import DatePicker from 'vue2-datepicker';

export default {
  name: 'dispute',
  components: {
    DatePicker
  },
  data() {
    return {
      legalEntity: {
        // keeps the value of legal entity input field
        value: null,
        text: null
      },
      operatingUnit: {
        // keeps the value of operating unit input field
        value: null,
        text: null
      },
      customerName: null, // keeps the value of customer name input field
      customerCode: null, // keeps the value of customer code input field
      transactionNo: null, // keeps the value of transaction number input field
      originalAmount: null, // keeps the value of original amount input field
      amountDue: null, // keeps the value of amount due input field
      disputeNo: null, // keeps the value of dispute no input field
      loading: false, // keeps the state of loading variable
      disputeDetailsFields: [
        // this array keeps the keys that comes in the header of the dispute details table
        {
          key: 'dispute_cat',
          class: 'col-fix text-center'
        },
        {
          key: 'dispute_sub_cat',
          class: 'col-fix text-center'
        },
        {
          key: 'writeoff_status',
          class: 'col-fix text-center'
        },
        {
          key: 'provision_status',
          class: 'col-fix text-center'
        },
        {
          key: 'lrn_number',
          class: 'col-fix text-center'
        },
        {
          key: 'awb_number',
          class: 'col-fix text-center'
        },
        {
          key: 'dispute_line_status',
          class: 'col-fix text-center'
        },
        {
          key: 'created_by',
          class: 'col-fix text-center'
        },
        {
          key: 'creation_date',
          class: 'col-fix text-center'
        },
        {
          key: 'last_updated_by',
          class: 'col-fix text-center'
        },
        {
          key: 'last_updation_date',
          class: 'col-fix text-center'
        },
        {
          key: 'weight',
          class: 'col-fix text-center'
        },
        {
          key: 'weight_uom',
          class: 'col-fix text-center'
        },
        {
          key: 'version',
          class: 'col-fix text-center'
        },
        {
          key: 'cn_type',
          class: 'col-fix text-center'
        },
        {
          key: 'je_header_id',
          class: 'col-fix text-center'
        },
        {
          key: 'je_line_id',
          class: 'col-fix text-center'
        },
        {
          key: 'customer_trx_id',
          class: 'col-fix text-center'
        },
        {
          key: 'customer_trx_line_id',
          class: 'col-fix text-center'
        }
      ],
      disputeDetailsData: [],
      disputeHeaderFields: [
        // this array keeps the keys that comes in the header of the dispute remarks table
        {
          key: 'transaction_num',
          class: 'col-fix text-center'
        },
        {
          key: 'dispute_status',
          class: 'col-fix text-center'
        },
        {
          key: 'dispute_amount',
          class: 'col-fix text-center'
        },
        {
          key: 'currency',
          class: 'col-fix text-center'
        },
        {
          key: 'created_by',
          class: 'col-fix text-center'
        },
        {
          key: 'creation_date',
          class: 'col-fix text-center'
        },
        {
          key: 'last_updated_by',
          class: 'col-fix text-center'
        },
        {
          key: 'last_updation_date',
          class: 'col-fix text-center'
        },
        {
          key: 'lob',
          class: 'col-fix text-center'
        },
        {
          key: 'exchange_rate',
          class: 'col-fix text-center'
        }
      ],
      disputeHeaderData: [],
      disputeType: 'dispute', // it keeps the state for the Radio buttons of Invoice Level and Delivery Level
      disputeAmount: 0, // it keeps the state for the Amount input form in the header section
      deliveryLevelData: [
        {
          trx_number: '',
          ref_trx_number: '',
          ref_trx_date: '',
          del_doc_num: '',
          dispute_category: '',
          dispute_sub_category: '',
          dispute_amount: '',
          remarks: '',
          deldocnumflag: true,
          trxnumflag: false,
          reftrxnumflag: false,
          reftrxdateflag: false
        }
      ], // this array keeps the data for the table inside the Delivery Level tab of accordian
      deliveryLevelFields: [
        // it keeps the fields of the table in the delivery level tab
        {
          key: 'selectBox',
          label: 'Invoice Level',
          stickyColumn: true,
          variant: 'info',
          class: 'stickyColumn'
        },
        { key: 'add_delivery', label: 'Add', stickyColumn: true },
        { key: 'remove_delivery', label: 'Remove', stickyColumn: true },
        {
          key: 'trx_number'
        },
        {
          key: 'ref_trx_number'
        },
        {
          key: 'ref_trx_date'
        },
        {
          key: 'del_doc_num'
        },
        {
          key: 'dispute_category'
        },
        {
          key: 'dispute_sub_category'
        },
        {
          key: 'dispute_amount'
        },
        {
          key: 'remarks'
        }
      ],
      invoiceLevelData: [
        {
          dispute_category: '',
          dispute_sub_category: '',
          dispute_amount: ''
        }
      ], // this array keeps the data for the table inside the Invoice Level tab of accordian
      invoiceLevelFields: [
        // it keeps the fields of the table in the invoice level tab
        { key: 'add_invoice', label: 'Add', stickyColumn: true },
        { key: 'remove_invoice', label: 'Remove', stickyColumn: true },
        {
          key: 'dispute_category'
        },
        {
          key: 'dispute_sub_category'
        },
        {
          key: 'dispute_amount'
        },
        {
          key: 'remarks'
        }
      ],
      savedData: [], // this array keeps the data for the table inside the Invoice Level tab of accordian
      savedFields: [
        // it keeps the fields of the table in the invoice level tab
        {
          key: 'ref_trx_id'
        },
        {
          key: 'ref_trx_number'
        },
        {
          key: 'ref_trx_date'
        },
        {
          key: 'del_doc_num'
        },
        {
          key: 'dispute_cat'
        },
        {
          key: 'dispute_sub_cat'
        },
        {
          key: 'dispute_amount'
        },
        {
          key: 'header_remark'
        },
        {
          key: 'detail_remark'
        }
      ],
      remarksData: [], // this array keeps the data to be shown inside the remarks table inside remarks tab
      remarksFields: [
        // this array keeps the fields to be shown inside the remarks table inside remarks tab
        {
          key: 'trx_number'
        },
        {
          key: 'ref_trx_number'
        },
        {
          key: 'delhivery_doc_number'
        },
        {
          key: 'remark'
        },
        {
          key: 'created_by'
        },
        {
          key: 'creation_date'
        }
      ],
      headerRemarks: '', // it keeps the value of the header remarks that is to be passed as payload from header table
      isSuccess: false, // it keeps the boolean state for if the api is successfully executed or not
      showAlert: false, // it keeps the state for if the alert message is to be shown or not
      responseMsg: '', // it keeps the state for the response message to be displayed on screen
      disputeHeaderId: null,
      remarks: null, // it keeps the state for the header remarks input form
      editMode: false, // it keeps the boolean state for whether the
      del_doc_num_flag: false // it keeps the
    };
  },
  props: ['searchDisputeParams'],
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'edit' || actionName === 'update') {
          this.editMode = true;
        }
        if (actionName === 'save') {
          this.addNewDispute();
        }
      }
    });
    if (this.searchDisputeParams) {
      this.getParentToChildData(this.searchDisputeParams); // assigns all the values that comes from previous record to the existing search params
    }
    if (this.searchDisputeParams.disputeHdrId) {
      this.disputeHeaderId = this.searchDisputeParams.disputeHdrId;
      this.getDisputeGridData();
      this.getRemarks();
    }
  },
  methods: {
    getParentToChildData(item) {
      this.legalEntity = item.legalEntity;
      this.operatingUnit = item.operatingUnit;
      this.customerName = item.customerName;
      this.customerCode = item.customerCode;
      this.transactionNo = item.transactionNo;
      this.originalAmount = item.originalAmount;
      this.amountDue = item.amountDue;
    },
    addNewRow(table) {
      // this function adds a new row on click of ad  d button inside the tables
      if (table === 'dispute') {
        this.deliveryLevelData.push({
          trx_number: '',
          ref_trx_number: '',
          ref_trx_date: '',
          del_doc_num: '',
          dispute_category: '',
          dispute_sub_category: '',
          dispute_amount: '',
          remarks: '',
          deldocnumflag: true,
          trxnumflag: false,
          reftrxnumflag: false,
          reftrxdateflag: false
        });
      }
    },
    removeRow(index, table) {
      // this function removes the row on click of add button inside the tables
      if (table === 'dispute') {
        this.deliveryLevelData.splice(index, 1);
      }
    },
    addNewDispute() {
      // this function adds a new dispute on click of New Dispute button in the header section based on the table that is filled
      if (this.disputeType === 'dispute') {
        let disputedetails = [];
        this.calculateTotal('dispute');
        for (let i of this.deliveryLevelData) {
          disputedetails.push(
          {
            dispute_dtl_id: 0,
            dispute_cat: i.dispute_category,
            dispute_sub_cat: i.dispute_sub_category,
            writeoff_status: '',
            provision_status: '',
            lrn_number: '',
            awb_number: '',
            dispute_line_status: 'OPEN',
            weight: '',
            weight_uom: '',
            customer_notification_type: '',
            je_header_id: '',
            je_line_id: '',
            customer_trx_id: '',
            customer_trx_line_id: '',
            iv_lr_basis: 0,
            trx_id: +this.searchDisputeParams.transactionId,
            trx_num: i.trx_number,
            trx_date: this.searchDisputeParams.glDate,
            lob: '',
            le_id: 67,
            ou_id: this.operatingUnit.value,
            exchange_rate: 1,
            ref_trx_id: 1234,
            ref_trx_number: i.ref_trx_number,
            ref_trx_date: i.ref_trx_date,
            delhivery_doc_number: i.del_doc_num,
            dispute_amount: i.dispute_amount,
            dtl_remarks:i.remarks
          }
          );
        }
        const payload = {
          cms_dispute_hdr: {
            dispute_hdr_id: 0,
            dispute_status: this.searchDisputeParams.status,
            dispute_amount: this.disputeAmount,
            currency: 'INR',
            customer_name: this.searchDisputeParams.customerName,
            customer_number: this.searchDisputeParams.customerCode,
            version: 1,
            hdr_remarks: this.headerRemarks,
            cms_dispute_dtl: disputedetails
          }
        };
        this.loading = true;
        this.$store
          .dispatch(
            'customerBalances/customerBalancesDisputeInsertion',
            payload
          )
          .then(response => {
            if (response.status === 200) {
              this.isSuccess = true;
              this.showAlert = true;
              this.responseMsg = response.data.message;
              this.disputeHeaderId = response.data.data;
              this.getRemarks();
            }
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    calculateTotal(level) {
      // this function calculates the total of the disputed amount when the Raise Dispute API is called and shows it inside the Dispute Amount input field
      let total = 0;
      if (level === 'dispute') {
        for (let i of this.deliveryLevelData) {
          total = +total + +i.dispute_amount;
        }
        this.disputeAmount = +total;
      }
    },
    getDisputeGridData() {
      // this function gets the Grid data present inside the Dispute Details tab and shows it inside the table if there exists and disputeHeaderId
      const payload = {
        _page: 0,
        _limit: 10,
        dispute_hdr_id: this.searchDisputeParams.disputeHdrId
      };
      this.loading = true;
      this.$store
        .dispatch('customerBalances/customerBalancesDetails', payload)
        .then(response => {
          if (response.status === 200) {
            this.deliveryLevelData = [];
            for (let i of response.data.data.page) {
              this.deliveryLevelData.push({
                trx_number: i.trx_num,
                ref_trx_number: i.ref_trx_num,
                ref_trx_date: i.ref_trx_date,
                del_doc_num: i.delhivery_doc_number,
                dispute_category: i.dispute_cat,
                dispute_sub_category: i.dispute_sub_cat,
                dispute_amount: i.dispute_amount,
                remarks: i.remark
              });
            }
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    selectBoxChecked(flag, index) {
      // this function tracks that if the checkbox is checked or not and perform on basis of checked or unchecked value
      this.deliveryLevelData[index].selectBox = flag;
      if (flag === true) {
        this.deliveryLevelData[index].trx_number = this.transactionNo;
        this.deliveryLevelData[index].trxnumflag = true;
        this.deliveryLevelData[index].ref_trx_number = '';
        this.deliveryLevelData[index].reftrxnumflag = true;
        this.deliveryLevelData[index].ref_trx_date = '';
        this.deliveryLevelData[index].reftrxdateflag = true;
        this.deliveryLevelData[index].del_doc_num = '';
        this.deliveryLevelData[index].deldocnumflag = false;
      } else {
        this.deliveryLevelData[index].del_doc_num = '';
        this.deliveryLevelData[index].deldocnumflag = true;
        this.deliveryLevelData[index].trx_number = '';
        this.deliveryLevelData[index].trxnumflag = false;
        this.deliveryLevelData[index].ref_trx_number = '';
        this.deliveryLevelData[index].reftrxnumflag = false;
        this.deliveryLevelData[index].ref_trx_date = '';
        this.deliveryLevelData[index].reftrxdateflag = false;
      }
    },
    getRemarks() {
      // this function gets the remarks through the get API and adds it to the table inside the Remarks tab
      const payload = {
        _page: 0,
        _limit: 10,
        dispute_hdr_id: this.disputeHeaderId
      };
      this.loading = true;
      this.$store
        .dispatch('customerBalances/customerBalancesRemarks', payload)
        .then(response => {
          if (response.status === 200) {
            this.remarksData = response.data.data.page;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
