import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import DatePicker from 'vue2-datepicker';
import customerBalancesDetailsData from './customerBalancesDetailsData';
import responseModal from '../../../shared/responseModal';
import dispute from './dispute';
import viewDisputes from './viewDisputes';
import createReceipt from './createReceipt';

export default {
  name: 'amountDue',
  components: {
    DatePicker,
    customerBalancesDetailsData,
    responseModal,
    dispute,
    viewDisputes,
    createReceipt
  },
  watch: {
    currentPage: function() {
      this.getAmountDue();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getAmountDue();
    },
    modalCurrentPage: function() {
      this.getCustomerBalancesId();
    }
  },
  props: ['searchParams'],
  data() {
    return {
      loading: false,
      currentPage: 1,
      legalEntity: {
        value: '',
        text: '',
        org_id: ''
      },
      defaultValue: {
        value: '',
        text: ''
      },
      operatingUnit: {
        value: '',
        text: ''
      },
      customerClass: {
        value: '',
        text: ''
      },
      customerStatus: {
        value: 'OP',
        text: 'Open'
      },
      transactionNo: {
        value: '',
        text: ''
      },
      perPage: commonHelper.perPageRecordData,
      pageOptions: commonHelper.getPageOption(),
      hq: '',
      glDateFrom: '',
      glDateTo: '',
      transactionType: '',
      customerNumber: '',
      customerCode: '',
      customerName: '',
      totalRows: null,
      parent_value_set_id: null,
      showValueSetModal: false,
      showOpenCustomerModal: false,
      showOpenResponseModal: false,
      requestParams: {},
      rowDetails: null,
      orderBy: 'DESC',
      orderName: 'amount_due',
      arrowSort: false,
      customerBalancesDetailsData: [],
      customerBalancesDetailsFields: [
        {
          key: 'trx_number',
          label: 'Transaction Number',
          class: 'col-fix text-center'
        },
        {
          key: 'amount_original',
          label: 'Original Amount',
          class: 'fix-amount text-right'
        },
        {
          key: 'amount_due',
          class: 'fix-amount text-right'
        },
        {
          key: 'delay_days',
          class: 'col-sm-2 text-center'
        },
        {
          key: 'gl_date',
          label: 'GL Date',
          class: 'col-fix text-center'
        },
        {
          key: 'le_name',
          label: 'Legal Entity',
          class: 'col-fix text-center'
        },
        {
          key: 'name',
          label: 'Operating Unit',
          class: 'col-fix text-center'
        },
        {
          key: 'class_type',
          class: 'col-fix text-center'
        },
        {
          key: 'status',
          class: 'col-fix text-center'
        },
        {
          key: 'hq_name',
          label: 'HQ Name',
          class: 'col-fix text-center'
        }
      ],
      transactionResponseData: [],
      transactionResponseFields: [
        {
          key: 'trx_number',
          label: 'Transaction Number'
        }
      ],
      showOpenDisputeModal: false,
      searchDisputeParams: {},
      showOpenDisputeViewModal: false,
      searchDisputeViewParams: {},
      modalCurrentPage: 1,
      modalTotalRows: null,
      perTransactionPage: commonHelper.perPageRecordData,
      count: 0,
      receiptModal: false,
      placeholder: false,
      payload: {}
    };
  },
  mounted() {
    if (this.searchParams) {
      this.getParentToChildData(this.searchParams);
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'download') {
          this.downloadExcelData();
        }
        if (
          actionName === 'add' &&
          !this.showOpenDisputeViewModal &&
          !this.showOpenCustomerModal &&
          !this.showOpenDisputeModal &&
          this.customerClass.text === 'Receipt'
        ) {
          this.openReceiptModal();
        }
        if (
          actionName === 'save' &&
          !this.showOpenDisputeViewModal &&
          !this.showOpenCustomerModal &&
          !this.showOpenDisputeModal
        ) {
          this.placeholder = true;
        }
      }
    });
    this.getAmountDue();
    this.getCustomerBalancesId();
  },
  methods: {
    downloadExcelData() {
      // this function gets the records of a particular page and then downlloads them into an excel sheet
      this.loading = true;
      const payload = { ...this.payload };
      payload._limit = this.totalRows;
      this.downloadExcel.downloadData(
        payload,
        'customerBalances/getCustomerBalancesData',
        'customer-balances-data',
        () => (this.loading = false),
        this.customerBalancesDetailsData
      );
    },
    mainSearch() {
      // this is the function that assigns the orderBy and orderName and then calls the main function that fetches the data and shows it inside the table
      this.orderBy = 'DESC';
      this.orderName = 'amount_due';
      this.getAmountDue();
    },
    formattedAmount(num) {
      return commonHelper.formatCurrencyAmount(num);
    },
    openValueSetModal(vsetCode) {
      // tbis functiom opens up the selected value set on click on the particular input field 
      this.showValueSetModal = true;
      this.vsetCode = vsetCode;
      this.parent_value_set_id = null;
      if (this.vsetCode === appStrings.VALUESETTYPE.CMS_OU) {
        this.parent_value_set_id = this.legalEntity.org_id;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      // this function assigns the values to selected valueSet option
      if (this.vsetCode === appStrings.VALUESETTYPE.ERP_LEGAL_ENTITIES) {
        this.legalEntity = {
          value: item.value_set_dtl_id,
          text: item.value_code,
          org_id: item.org_id
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.CMS_OU) {
        this.operatingUnit = {
          value: item.erp_org_id,
          text: item.org_name
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.CUSTOMER_CLASS) {
        this.customerClass = {
          value: item.value_code,
          text: item.value_meaning
        };
        if (this.customerClass.text !== 'Receipt') {
          this.customerBalancesDetailsFields.splice(0, 0, {
            key: 'dispute',
            class: 'col-sm-2 text-center'
          });
        } else if (this.customerClass.text === 'Receipt') {
          this.customerBalancesDetailsFields = [
            {
              key: 'trx_number',
              label: 'Transaction Number',
              class: 'col-fix text-center'
            },
            {
              key: 'amount_original',
              label: 'Original Amount',
              class: 'fix-amount text-right'
            },
            {
              key: 'amount_due',
              class: 'fix-amount text-right'
            },
            {
              key: 'delay_days',
              class: 'col-sm-2 text-center'
            },
            {
              key: 'gl_date',
              label: 'GL Date',
              class: 'col-fix text-center'
            },
            {
              key: 'le_name',
              label: 'Legal Entity',
              class: 'col-fix text-center'
            },
            {
              key: 'name',
              label: 'Operating Unit',
              class: 'col-fix text-center'
            },
            {
              key: 'class_type',
              class: 'col-fix text-center'
            },
            {
              key: 'status',
              class: 'col-fix text-center'
            },
            {
              key: 'hq_name',
              label: 'HQ Name',
              class: 'col-fix text-center'
            }
          ];
        }
        this.getCustomerBalancesId();
      } else if (this.vsetCode === appStrings.VALUESETTYPE.CUSTOMER_STATUS) {
        this.customerStatus = {
          value: item.value_code,
          text: item.value_meaning
        };
      }
    },
    closeValueSetModal() {
      // this function closes the value set
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode) {
      // this function clears the value of the particular valueSet on click of cross on that particular valueSet
      if (vsetCode === this.legalEntity.value) {
        this.legalEntity = this.defaultValue;
      } else if (vsetCode === this.customerClass.value) {
        this.customerClass = this.defaultValue;
        this.transactionNo = this.defaultValue;
      } else if (vsetCode === this.operatingUnit.value) {
        this.operatingUnit = this.defaultValue;
      } else if (vsetCode === this.customerStatus.value) {
        this.customerStatus = this.defaultValue;
      }
    },
    getCustomerBalancesId() {
      // this function fetched the Transaction Numbers from the API and gives them to the responseModal
      this.payload = {
        _page: this.modalCurrentPage - 1,
        _limit: 10000,
        legal_entity_id: this.legalEntity.value,
        organization_id: '',
        customer_id: this.searchParams.customer_id,
        class_type: this.customerClass.value
      };
      this.loading = true;
      this.$store
        .dispatch('customerBalances/getCustomerBalancesTransactionsId', this.payload)
        .then(response => {
          if (response.status === 200) {
            this.transactionResponseData = response.data.data.data.page;
            this.modalTotalRows = response.data.data.data.total_elements;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getAmountDue() {
      // this is the main function that fetched the data from the get API and shows it inside the main table
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        legal_entity_id: this.legalEntity.value,
        customer_name: this.customerName,
        customer_number: this.customerNumber,
        customer_id: this.searchParams.customer_id,
        organization_id: this.operatingUnit.value,
        class_type: this.customerClass.value,
        gl_date_from: this.glDateFrom,
        gl_date_to: this.glDateTo,
        hq_name: this.hq,
        trx_number: this.transactionNo.value,
        status: this.customerStatus.value,
        order_by: this.orderBy,
        order_name: this.orderName
      };
      this.loading = true;
      this.$store
        .dispatch('customerBalances/getCustomerBalancesData', payload)
        .then(response => {
          if (response.status === 200) {
            this.customerBalancesDetailsData = response.data.data.data.page;
            this.$store.commit(
              'customerBalances/setCustomerBalancesDetailsData',
              this.customerBalancesDetailsData
            );
            this.totalRows = response.data.data.data.total_elements;
            for (let i of this.customerBalancesDetailsData) {
              if (i.class_type === 'CM') {
                i.class_type = 'Credit Memo';
              } else if (i.class_type === 'PMT') {
                i.class_type = 'Receipt';
              } else if (i.class_type === 'INV') {
                i.class_type = 'Invoice';
              } else if (i.class_type === 'DM') {
                i.class_type = 'Debit Memo';
              }
            }
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    clear() {
      // this function clears the values of all the search filters and the table on click of Clear button
      this.legalEntity = this.defaultValue;
      this.operatingUnit = this.defaultValue;
      this.customerClass = this.defaultValue;
      this.customerStatus = this.defaultValue;
      this.hq = null;
      this.glDateFrom = null;
      this.glDateTo = null;
      this.transactionNo = this.defaultValue;
      this.customerCode = null;
      this.transactionType = null;
      this.customerName = null;
      this.customerBalancesDetailsData = [];
    },
    getParentToChildData(item) {
      // this function takes the values that comes in props and then assigns those values to existing states
      this.legalEntity = {
        value: item.le_id.value,
        text: item.le_id.text,
        org_id: item.le_id.org_id
      };
      if (item.class_type) {
        this.customerClass = {
          value: item.class_type.value,
          text: item.class_type.text
        };
        if (this.customerClass.text !== 'Receipt') {
          this.customerBalancesDetailsFields.splice(0, 0, {
            key: 'dispute',
            class: 'col-sm-2 text-center'
          });
        }
      }
      this.org_id = item.le_id.org_id;
      this.customerName = item.customer_name;
      this.customerNumber = item.customer_number;
      this.customerCode = item.customer_number;
      // this.operatingUnit.text = item.name;
      // this.operatingUnit.value = item.org_id;
    },
    rowSelected(items) {
      // this function checks which row of the table is clicked and then assignsz the values of that row to requestParams
      this.showOpenCustomerModal = true;
      this.rowDetails = items;
      this.requestParams = {
        trx_id: items.trx_id,
        class_type: items.class_type,
        trx_number: items.trx_number,
        customer_id: items.customer_id
      };
    },
    openDisputeModal(item) {
      // this function first checks the value of count state and then on that basis it opens different screens
      if (this.count <= 0) {
        this.searchDisputeParams = {
          legalEntity: {
            value: item.legal_entity_id,
            text: item.le_name,
            org_id: this.org_id
          },
          operatingUnit: {
            value: item.organization_id,
            text: item.name
          },
          class: item.class_type,
          status: item.status,
          hqName: item.hq_name,
          glDate: item.gl_date,
          transactionNo: item.trx_number,
          transactionId: item.trx_id,
          originalAmount: item.amount_original,
          amountDue: item.amount_due,
          delayDays: item.delay_days,
          customerName: this.customerName,
          customerCode: this.customerCode
        };
        this.showOpenDisputeModal = true;
      } else {
        this.searchDisputeViewParams = {
          legalEntity: {
            value: item.legal_entity_id,
            text: item.le_name,
            org_id: this.legalEntity.org_id
          },
          operatingUnit: {
            value: item.organization_id,
            text: item.name
          },
          class: item.class_type,
          status: item.status,
          hqName: item.hq_name,
          glDate: item.gl_date,
          transactionNo: item.trx_number,
          transactionId: item.trx_id,
          originalAmount: item.amount_original,
          amountDue: item.amount_due,
          delayDays: item.delay_days,
          customerName: this.customerName,
          customerCode: this.customerCode
        };
        this.showOpenDisputeViewModal = true;
      }
    },
    customerModal(flag) {
      this.showOpenCustomerModal = flag;
    },
    disputeModal(flag) {
      this.showOpenDisputeModal = flag;
    },
    disputeViewModal(flag) {
      this.showOpenDisputeViewModal = flag;
    },
    responseModal(flag) {
      this.showOpenResponseModal = flag;
    },
    truncateComment(souce, size) {
      return commonHelper.truncate(souce, size);
    },
    sortAmount(item) {
      if (item === 'Amount Low to High') {
        this.arrowSort = true;
        this.orderBy = 'ASC';
      } else {
        this.arrowSort = false;
        this.orderBy = 'DESC';
      }
      this.getAmountDue();
    },
    openCloseResponseModal() {
      this.showOpenResponseModal = true;
    },
    selectedTransactionNumber(flag, trxnNumber) {
      this.showOpenResponseModal = flag;
      this.transactionNo = trxnNumber;
    },
    openDisputestModal() {
      this.showOpenDisputeModal = true;
    },
    getDisputes(item, trxno) {
      // this function gets the number that a particular record contains any dispute or not and on basis of that it opens the dispute screen or the viewDispute screen
      const payload = {
        _page: 0,
        _limit: 1000,
        trx_num: trxno
      };
      this.loading = true;
      this.$store
        .dispatch('customerBalances/customerBalancesDisputeData', payload)
        .then(response => {
          if (response.status === 200) {
            this.count = response.data.data.total_elements;
            this.openDisputeModal(item);
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    clearResponseModal(modal) {
      // this function clears the value of the Transaction Number input field on click of cross inside the input field
      if (modal === 'Transaction No.') {
        this.transactionNo = this.defaultValue;
      }
    },
    openShowReceiptModal(flag) {
      // this function opens or closes the Create receipt Modal
      this.receiptModal = flag;
    },
    openReceiptModal() {
      // this function opens the Create Receipt modal on press of add accessrightbutton and then sends searchparams to that modal
      this.searchParameters = {
        legalEntity: this.legalEntity,
        operatingUnit: this.operatingUnit
      };
      this.receiptModal = true;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
