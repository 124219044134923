import appStrings from '@/app/utility/string.utility';
import DatePicker from 'vue2-datepicker';
import commonHelper from '@/app/utility/common.helper.utility';
import { ModelSelect } from 'vue-search-select';
import customerBalancesData from './customerBalancesData';
import XLSX from 'xlsx';
import URL_UTILITY from '../../../utility/url.utility';

export default {
  name: 'customerBalances',
  components: {
    DatePicker,
    ModelSelect,
    customerBalancesData
  },
  watch: {
    currentPage: function() {
      this.getCustomerBalances();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getCustomerBalances();
    }
  },
  data() {
    return {
      loader: false,
      loading: false,
      searchParams: {},
      arrowTAD: false,
      arrowRAD: false,
      arrowIAD: false,
      arrowCAD: false,
      arrowDAD: false,
      sortFlag: {
        tad: true,
        rad: true,
        iad: true,
        cad: true,
        dad: true
      },
      orderBy: 'DESC',
      orderName: 'inv_amount_due',
      secondFormHeading: null,
      tab: null,
      showHideFirstModal: false,
      showValueSetModal: false,
      legalEntity: {
        value: '',
        text: ''
      },
      defaultValue: {
        value: '',
        text: ''
      },
      amountFrom: '',
      amountTo: '',
      customerNumber: '',
      customerName: '',
      perPage: commonHelper.perPageRecordData,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      currentPage: 1,
      totalModalFlag: false,
      customerBalancesData: [],
      customerBalancesFields: [
        {
          key: 's_no',
          label: 'S.No',
          stickyColumn: true,
          class: 'selectradio',
          variant: 'primary'
        },
        {
          key: 'le_name',
          label: 'Legal Entity',
          class: 'col-sm-1 text-center sticky-col1'
        },
        {
          key: 'customer_number',
          label: 'Customer No',
          class: 'col-sm-1 text-center sticky-col2'
        },
        {
          key: 'customer_name',
          label: 'Customer Name',
          class: 'col-fix-name1 text-center sticky-col3'
        },
        {
          key: 'total_amount_due',
          label: 'Total Amount Due',
          class: 'fix-amount text-right',
          variant: 'success'
        },
        {
          key: 'pmt_amount_due',
          label: 'Receipt Amount Due',
          class: 'fix-amount text-right',
          variant: 'success'
        },
        {
          key: 'inv_amount_due',
          label: 'Invoice Amount Due',
          class: 'fix-amount text-right',
          variant: 'success'
        },
        {
          key: 'cm_amount_due',
          label: 'CM Amount Due',
          class: 'fix-amount text-right',
          variant: 'success'
        },
        {
          key: 'dm_amount_due',
          label: 'DM Amount Due',
          class: 'fix-amount text-right',
          variant: 'success'
        },
        {
          key: 'b2b_postpaid',
          label: 'B2B Postpaid',
          class: 'fix-amount text-right',
          variant: 'primary'
        },
        {
          key: 'b2b_prepaid',
          label: 'B2B Prepaid',
          class: 'fix-amount text-right',
          variant: 'primary'
        },
        {
          key: 'b2c_prepaid',
          label: 'B2C Prepaid',
          class: 'fix-amount text-right',
          variant: 'primary'
        },
        {
          key: 'b2c_postpaid',
          label: 'B2C Postpaid',
          class: 'fix-amount text-right',
          variant: 'primary'
        },
        {
          key: 'b2c_cod_netoff',
          label: 'B2C COD Netoff',
          class: 'fix-amount text-right',
          variant: 'primary'
        },
        {
          key: 'b2b_c2c',
          label: 'B2B, C2C',
          class: 'fix-amount text-right',
          variant: 'primary'
        },
        {
          key: 'skynet_int',
          label: 'Skynet Int',
          class: 'fix-amount text-right',
          variant: 'primary'
        },
        {
          key: 'b2b_retail',
          label: 'B2B Retail',
          class: 'fix-amount text-right',
          variant: 'primary'
        },
        {
          key: 'fc_invoice',
          label: 'FC Invoice',
          class: 'fix-amount text-right',
          variant: 'primary'
        },
        {
          key: 'e2e_services_invoice',
          label: 'E2E Services Invoice',
          class: 'fix-amount text-right',
          variant: 'primary'
        },
        {
          key: 'ftl_services',
          label: 'FTL Services',
          class: 'fix-amount text-right',
          variant: 'primary'
        },
        {
          key: 'hkd_skynet',
          label: 'HKD Skynet',
          class: 'fix-amount text-right',
          variant: 'primary'
        },
        {
          key: 'others',
          label: 'Others',
          class: 'fix-amount text-right',
          variant: 'primary'
        },
        {
          key: 'not_due',
          label: 'Not Due',
          class: 'fix-amount text-right',
          variant: 'warning'
        },
        {
          key: 'zero_to_thirty',
          label: '0 to 30',
          class: 'fix-amount text-right',
          variant: 'warning'
        },
        {
          key: 'thirtyone_to_sixty',
          label: '31 to 60',
          class: 'fix-amount text-right',
          variant: 'warning'
        },
        {
          key: 'sixtyone_to_ninety',
          label: '61 to 90',
          class: 'fix-amount text-right',
          variant: 'warning'
        },
        {
          key: 'ninetyone_to_onetwenty',
          label: '91 to 120',
          class: 'fix-amount text-right',
          variant: 'warning'
        },
        {
          key: 'onetwnentyone_to_oneeighty',
          label: '121 to 180',
          class: 'fix-amount text-right',
          variant: 'warning'
        },
        {
          key: 'oneeightyone_to_threesixtyfive',
          label: '181 to 365',
          class: 'fix-amount text-right',
          variant: 'warning'
        },
        {
          key: 'more_than_threesixtyfive',
          label: '> 365',
          class: 'fix-amount text-right',
          variant: 'warning'
        }
      ],
      totalFields: [
        {
          key: 'total_amount_due',
          label: 'Total Amount Due',
          class: 'col-fix text-right'
        },
        {
          key: 'pmt_amount_due',
          label: 'Receipt Amount Due',
          class: 'col-fix text-right'
        },
        {
          key: 'inv_amount_due',
          label: 'Invoice Amount Due',
          class: 'col-fix text-right'
        },
        {
          key: 'cm_amount_due',
          label: 'CM Amount Due',
          class: 'text-right'
        },
        {
          key: 'dm_amount_due',
          label: 'DM Amount Due',
          class: 'col-fix text-right'
        },
        {
          key: 'others',
          label: 'Others',
          class: 'fix-amount text-right'
        },
        {
          key: 'b2b_postpaid',
          label: 'B2B Postpaid',
          class: 'fix-amount text-right'
        },
        {
          key: 'b2b_prepaid',
          label: 'B2B Prepaid',
          class: 'fix-amount text-right'
        },
        {
          key: 'b2c_prepaid',
          label: 'B2C Prepaid',
          class: 'fix-amount text-right'
        },
        {
          key: 'b2c_postpaid',
          label: 'B2C Postpaid',
          class: 'fix-amount text-right'
        },
        {
          key: 'b2c_cod_netoff',
          label: 'B2C COD Netoff',
          class: 'fix-amount text-right'
        },
        {
          key: 'b2b_c2c',
          label: 'B2B, C2C',
          class: 'fix-amount text-right'
        },
        {
          key: 'skynet_int',
          label: 'Skynet Int',
          class: 'fix-amount text-right'
        },
        {
          key: 'b2b_retail',
          label: 'B2B Retail',
          class: 'fix-amount text-right'
        },
        {
          key: 'fc_invoice',
          label: 'FC Invoice',
          class: 'fix-amount text-right'
        },
        {
          key: 'e2e_services_invoice',
          label: 'E2E Services Invoice',
          class: 'fix-amount text-right'
        },
        {
          key: 'ftl_services',
          label: 'FTL Services',
          class: 'fix-amount text-right'
        },
        {
          key: 'hkd_skynet',
          label: 'HKD Skynet',
          class: 'fix-amount text-right'
        },
        {
          key: 'not_due',
          label: 'Not Due',
          class: 'fix-amount text-right'
        },
        {
          key: 'zero_to_thirty',
          label: '0 to 30',
          class: 'fix-amount text-right'
        },
        {
          key: 'thirtyone_to_sixty',
          label: '31 to 60',
          class: 'fix-amount text-right'
        },
        {
          key: 'sixtyone_to_ninety',
          label: '61 to 90',
          class: 'fix-amount text-right'
        },
        {
          key: 'ninetyone_to_onetwenty',
          label: '91 to 120',
          class: 'fix-amount text-right'
        },
        {
          key: 'onetwnentyone_to_oneeighty',
          label: '121 to 180',
          class: 'fix-amount text-right'
        },
        {
          key: 'oneeightyone_to_threesixtyfive',
          label: '181 to 365',
          class: 'fix-amount text-right'
        },
        {
          key: 'more_than_threesixtyfive',
          label: '> 365',
          class: 'fix-amount text-right'
        }
      ],
      totalAmountFlag: false,
      totalAmountsData: [],
      businessMethodModal: false,
      businessMethodModalData: [
        {
          business_method: 'Others'
        },
        {
          business_method: 'B2B Postpaid'
        },
        {
          business_method: 'B2B Prepaid'
        },
        {
          business_method: 'B2C Prepaid'
        },
        {
          business_method: 'B2C Postpaid'
        },
        {
          business_method: 'B2C COD Netoff'
        },
        {
          business_method: 'B2B, C2C'
        },
        {
          business_method: 'Skynet Int'
        },
        {
          business_method: 'B2B Retail'
        },
        {
          business_method: 'FC Invoice'
        },
        {
          business_method: 'E2E Services Invoice'
        },
        {
          business_method: 'FTL Services'
        },
        {
          business_method: 'HKD Skynet'
        },
        {
          business_method: 'Not Due'
        },
        {
          business_method: '0 to 30'
        },
        {
          business_method: '31 to 60'
        },
        {
          business_method: '61 to 90'
        },
        {
          business_method: '91 to 120'
        },
        {
          business_method: '121 to 180'
        },
        {
          business_method: '> 180'
        }
      ],
      businessMethodModalFields: [
        {
          key: 'selectBox',
          label: 'Select',
          stickyColumn: true,
          variant: 'info',
          class: 'stickyColumn'
        },
        {
          key: 'business_method',
          class: 'col-fix text-center'
        }
      ],
      selectedBusiness: '',
      selectedBusinessMethod: [],
      selectAllCheckBox: false,
      downloadingData: [],
      load: false,
      searchParameters: {},
      selectRadio: false,
      placeholder: false,
      unsubscribe: null,
      dowloadReport: URL_UTILITY.getDownloadReportUrl,
      perPageDownload: 10000
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'download' && !this.showHideFirstModal) {
          // this.downloadTotalDataAsExcel();
          alert("Download process has been started. Please wait!")
          this.saveSubmitRequest();
        }
      }
    });
  },
  methods: {
    saveSubmitRequest() {
      const payload = {
        admin_submit_req_details: [
          {
            request_num: 0,
            request_id: 245,
            template_id: 75,
            schedule_type: 'asap', //pass hardcode
            request_start_date: null,
            request_end_date: null,
            resubmit_interval: null,
            resubmit_interval_unit: null,
            day_of_month: null,
            day_of_week: null,
            output_format_id: 'XLS', //pass hardcode
            no_of_args: 25,
            request_parameter:
              this.legalEntity.value +
              ',' +
              this.customerName +
              ',' +
              this.customerNumber +
              ',' +
              this.amountFrom +
              ',' +
              this.amountTo +
              ',' +
              this.currentPage -
              1 +
              ',' +
              this.perPageDownload,
            sms_flag: false,
            email_flag: false,
            whatsapp_flag: false,
            child_req_count: null,
            email_comm_template_id: null,
            sms_comm_template_id: null,
            whatsapp_comm_template_id: null,
            argument1: this.legalEntity.value,
            argument2: this.customerName,
            argument3: this.customerNumber,
            argument4: this.amountFrom,
            argument5: this.amountTo,
            argument6: this.currentPage - 1,
            argument7: this.perPageDownload,
            argument8: null,
            argument9: null,
            argument10: null,
            argument11: null,
            argument12: null,
            argument13: null,
            argument14: null,
            argument15: null,
            argument16: null,
            argument17: null,
            argument18: null,
            argument19: null,
            argument20: null,
            argument21: null,
            argument22: null,
            argument23: null,
            argument24: null,
            argument25: null
          }
        ]
      };
      this.loader = true;
      this.$store
        .dispatch('template/saveSubmitRequest', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 201) {
            this.requestNumId = resp.data.data[0].id;
            this.getSubmitRequest();
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getSubmitRequest() {
      this.loader = true;
      this.$store
        .dispatch('template/getSubmitRequest', this.requestNumId)
        .then(resp => {
          if (resp.status === 200) {
            this.requestStatus = resp.data.data[0].status;
            if (this.requestStatus === 'COMPLETED') {
              this.loader = false;
              alert("Download has been completed!");
              window.location.href = this.dowloadReport + '/' + this.requestNumId;
            } else if (this.requestStatus === 'ERROR') {
              this.loader = false;
              alert('Request in ERROR can not Download');
            } else {
              setTimeout(() => {
                this.getSubmitRequest();
              }, 3000);
              
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    tempFunction() {
      this.sortFlag = {
        tad: true,
        rad: true,
        iad: true,
        cad: true,
        dad: true
      };
      this.orderBy = 'DESC';
      this.orderName = 'total_amount_due';
      this.getCustomerBalances();
    },
    formattedAmount(num) {
      // this function formats the amount that is wrapped inside this function into Indian Currency System with rupee symbol
      return commonHelper.formatCurrencyAmount(num);
    },
    openValueSetModal(vsetCode, valueType) {
      // this function opens the selected valueSet
      this.showValueSetModal = true;
      this.vsetCode = vsetCode;
      this.valueType = valueType;
      this.parent_value_set_id = null;
      if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.parent_value_set_id = this.legalEntity.value;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      // this function stores the selected value of a valueSet inside the variable assiged to that particular valueSet
      if (this.vsetCode === appStrings.VALUESETTYPE.ERP_LEGAL_ENTITIES) {
        this.legalEntity = {
          value: item.erp_org_id,
          text: item.org_name
        };
      }
    },
    closeValueSetModal() {
      // this function closes the selected valueset modal on click of cross button on that particular valueSet input
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode) {
      // this function clears the selected value inside the Legal Entity valueSet
      if (vsetCode === this.legalEntity.value) {
        this.legalEntity = this.defaultValue;
      }
    },
    clear() {
      // this function clears all the search filters on click of Clear button
      this.legalEntity = this.defaultValue;
      this.customerNumber = '';
      this.customerName = '';
      this.amountFrom = '';
      this.amountTo = '';
      this.searchParams = '';
      this.customerBalancesData = [];
    },
    getCustomerBalances() {
      // this is the main function that fetched all the records that are to be shown in the table of the first screen
      if (this.legalEntity.value || this.customerName || this.customerNumber) {
        this.customerBalancesData == [];
        this.totalAmountsData = [];
        this.totalAmountFlag = false;
        this.totalAmounts();
        this.payload = {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          legal_entity_id: this.legalEntity.value,
          // order_by: this.orderBy,
          // order_name: this.orderName,
          customer_name: this.customerName,
          customer_number: this.customerNumber,
          amount_from: this.amountFrom,
          amount_to: this.amountTo
        };
        this.loading = true;
        this.$store
          .dispatch('customerBalances/getCustomerBalances', this.payload)
          .then(response => {
            if (response.status === 200) {
              this.customerBalancesData = response.data.data.data.page;
              this.totalRows = response.data.data.data.total_elements;
              for (let i = 0; i < this.customerBalancesData.length; i++) {
                this.customerBalancesData[i].s_no =
                  this.perPage * (this.currentPage - 1) + i + 1;
              }
              if (this.customerBalancesData == []) {
                alert('Session Timed Out!');
              }
            }
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        alert('Please select either of the required fields!');
      }
    },
    showSecondForm(flag, component, item) {
      // this function selects which class is to be shown inside the second screen modal and sends searchParams based on that class
      if (component === 'totalAmountDue') {
        this.secondFormHeading = 'Total Amount Due';
        this.searchParams = {
          le_id: {
            value: item.legal_entity_id,
            text: item.le_name,
            org_id: item.org_id
          },
          customer_name: item.customer_name,
          customer_number: item.customer_number,
          customer_id: item.customer_id,
          org_id: item.org_id,
          name: item.name
        };
      } else if (component === 'receiptAmountDue') {
        this.secondFormHeading = 'Receipt Amount Due';
        this.searchParams = {
          le_id: {
            value: item.legal_entity_id,
            text: item.le_name
          },
          customer_name: item.customer_name,
          customer_number: item.customer_number,
          customer_id: item.customer_id,
          org_id: item.org_id,
          name: item.name,
          class_type: {
            value: 'PMT',
            text: 'Receipt'
          }
        };
      } else if (component === 'invoiceAmountDue') {
        this.secondFormHeading = 'Invoice Amount Due';
        this.searchParams = {
          le_id: {
            value: item.legal_entity_id,
            text: item.le_name
          },
          customer_name: item.customer_name,
          customer_number: item.customer_number,
          customer_id: item.customer_id,
          class_type: {
            value: 'INV',
            text: 'Invoice'
          },
          org_id: item.org_id,
          name: item.name
        };
      } else if (component === 'cmAmountDue') {
        this.secondFormHeading = 'Credit Memo Amount Due';
        this.searchParams = {
          le_id: {
            value: item.legal_entity_id,
            text: item.le_name
          },
          customer_name: item.customer_name,
          customer_number: item.customer_number,
          customer_id: item.customer_id,
          class_type: {
            value: 'CM',
            text: 'Credit Memo'
          },
          org_id: item.org_id,
          name: item.name
        };
      } else if (component === 'dmAmountDue') {
        this.secondFormHeading = 'Debit Memo Amount Due';
        this.searchParams = {
          le_id: {
            value: item.legal_entity_id,
            text: item.le_name
          },
          customer_name: item.customer_name,
          customer_number: item.customer_number,
          customer_id: item.customer_id,
          class_type: {
            value: 'DM',
            text: 'Debit Memo'
          },
          org_id: item.org_id,
          name: item.name
        };
      }
      this.showHideFirstModal = flag;
    },
    openShowModal(flag, component) {
      // this function opens the second screen modal
      this.showHideFirstModal = flag;
      this.tab = component;
    },
    openShowBusinessMethodModal(flag) {
      // this function opens and closes the business method modal
      this.businessMethodModal = flag;
    },
    sortAmount(item) {
      // this function does the sorting part based on the arrow of that particular column
      if (item === 'Total Amount Low to High') {
        this.sortFlag = {
          tad: true,
          rad: false,
          iad: false,
          cad: false,
          dad: false
        };
        this.arrowTAD = true;
        this.orderBy = 'ASC';
        this.orderName = 'total_amount_due';
      } else if (item === 'Total Amount High to Low') {
        this.sortFlag = {
          tad: true,
          rad: false,
          iad: false,
          cad: false,
          dad: false
        };
        this.arrowTAD = false;
        this.orderBy = 'DESC';
        this.orderName = 'total_amount_due';
      } else if (item === 'Receipt Amount Low to High') {
        this.sortFlag = {
          tad: false,
          rad: true,
          iad: false,
          cad: false,
          dad: false
        };
        this.arrowRAD = true;
        this.orderBy = 'ASC';
        this.orderName = 'pmt_amount_due';
      } else if (item === 'Receipt Amount High to Low') {
        this.sortFlag = {
          tad: false,
          rad: true,
          iad: false,
          cad: false,
          dad: false
        };
        this.arrowRAD = false;
        this.orderBy = 'DESC';
        this.orderName = 'pmt_amount_due';
      } else if (item === 'Invoice Amount Low to High') {
        this.sortFlag = {
          tad: false,
          rad: false,
          iad: true,
          cad: false,
          dad: false
        };
        this.arrowIAD = true;
        this.orderBy = 'ASC';
        this.orderName = 'inv_amount_due';
      } else if (item === 'Invoice Amount High to Low') {
        this.sortFlag = {
          tad: false,
          rad: false,
          iad: true,
          cad: false,
          dad: false
        };
        this.arrowIAD = false;
        this.orderBy = 'DESC';
        this.orderName = 'inv_amount_due';
      } else if (item === 'CM Amount Low to High') {
        this.sortFlag = {
          tad: false,
          rad: false,
          iad: false,
          cad: true,
          dad: false
        };
        this.arrowCAD = true;
        this.orderBy = 'ASC';
        this.orderName = 'cm_amount_due';
      } else if (item === 'CM Amount High to Low') {
        this.sortFlag = {
          tad: false,
          rad: false,
          iad: false,
          cad: true,
          dad: false
        };
        this.arrowCAD = false;
        this.orderBy = 'DESC';
        this.orderName = 'cm_amount_due';
      } else if (item === 'DM Amount Low to High') {
        this.sortFlag = {
          tad: false,
          rad: false,
          iad: false,
          cad: false,
          dad: true
        };
        this.arrowDAD = true;
        this.orderBy = 'ASC';
        this.orderName = 'dm_amount_due';
      } else if (item === 'DM Amount High to Low') {
        this.sortFlag = {
          tad: false,
          rad: false,
          iad: false,
          cad: false,
          dad: true
        };
        this.arrowDAD = false;
        this.orderBy = 'DESC';
        this.orderName = 'dm_amount_due';
      }

      this.getCustomerBalances();
    },
    truncateComment(souce, size) {
      // this function makes a tooltip of longer strings and shows it on hover
      return commonHelper.truncate(souce, size);
    },
    totalAmounts() {
      // this function totals all the numeric data that comes in the main table
      this.totalAmountsData == [];
      const payload = {
        _page: 0,
        _limit: 100000,
        legal_entity_id: this.legalEntity.value,
        // order_by: this.orderBy,
        // order_name: this.orderName,
        customer_name: this.customerName,
        customer_number: this.customerNumber,
        amount_from: this.amountFrom,
        amount_to: this.amountTo
      };
      this.loader = true;
      this.downloadingData = [];
      this.$store
        .dispatch('customerBalances/getCustomerBalances', payload)
        .then(response => {
          if (response.status === 200) {
            const totalamountdata = response.data.data.data.page;
            let totalamountdue = 0;
            let receiptamountdue = 0;
            let invoiceamountdue = 0;
            let cmamountdue = 0;
            let dmamountdue = 0;
            let others = 0;
            let b2bpostpaid = 0;
            let b2bprepaid = 0;
            let b2cprepaid = 0;
            let b2cpostpaid = 0;
            let b2ccod = 0;
            let b2bc2c = 0;
            let skynetint = 0;
            let b2bretail = 0;
            let fcinvoice = 0;
            let e2eserviceinvoice = 0;
            let ftlservices = 0;
            let hkdskynet = 0;
            let notdue = 0;
            let zerotothirty = 0;
            let thirtyonetosixty = 0;
            let sixtyonetoninety = 0;
            let ninetyonetoonetwenty = 0;
            let onetwentyonetooneeighty = 0;
            let oneeightytothreesixtyfice = 0;
            let morethanthreesixtyfive = 0;
            for (let i of totalamountdata) {
              totalamountdue = totalamountdue + +i.total_amount_due;
              receiptamountdue = receiptamountdue + +i.pmt_amount_due;
              invoiceamountdue = invoiceamountdue + +i.inv_amount_due;
              cmamountdue = cmamountdue + +i.cm_amount_due;
              dmamountdue = dmamountdue + +i.dm_amount_due;
              others = others + +i.others;
              b2bpostpaid = b2bpostpaid + +i.b2b_postpaid;
              b2bprepaid = b2bprepaid + +i.b2b_prepaid;
              b2cprepaid = b2cprepaid + +i.b2c_prepaid;
              b2cpostpaid = b2cpostpaid + +i.b2c_postpaid;
              b2ccod = b2ccod + +i.b2c_cod_netoff;
              b2bc2c = b2bc2c + +i.b2b_c2c;
              skynetint = skynetint + +i.skynet_int;
              b2bretail = b2bretail + +i.b2b_retail;
              fcinvoice = fcinvoice + +i.fc_invoice;
              e2eserviceinvoice = e2eserviceinvoice + +i.e2e_services_invoice;
              ftlservices = ftlservices + +i.ftl_services;
              hkdskynet = hkdskynet + +i.hkd_skynet;
              notdue = notdue + +i.not_due;
              zerotothirty = zerotothirty + +i.zero_to_thirty;
              thirtyonetosixty = thirtyonetosixty + +i.thirtyone_to_sixty;
              sixtyonetoninety = sixtyonetoninety + +i.sixtyone_to_ninety;
              ninetyonetoonetwenty =
                ninetyonetoonetwenty + +i.ninetyone_to_onetwenty;
              onetwentyonetooneeighty =
                onetwentyonetooneeighty + +i.onetwnentyone_to_oneeighty;
              oneeightytothreesixtyfice =
                oneeightytothreesixtyfice + +i.oneeightyone_to_threesixtyfive;
              morethanthreesixtyfive =
                morethanthreesixtyfive + +i.more_than_threesixtyfive;
            }
            this.totalAmountsData = [
              {
                total_amount_due: this.formattedAmount(totalamountdue),
                pmt_amount_due: this.formattedAmount(receiptamountdue),
                inv_amount_due: this.formattedAmount(invoiceamountdue),
                cm_amount_due: this.formattedAmount(cmamountdue),
                dm_amount_due: this.formattedAmount(dmamountdue),
                others: this.formattedAmount(others),
                b2b_postpaid: this.formattedAmount(b2bpostpaid),
                b2b_prepaid: this.formattedAmount(b2bprepaid),
                b2c_prepaid: this.formattedAmount(b2cprepaid),
                b2c_postpaid: this.formattedAmount(b2cpostpaid),
                b2c_cod_netoff: this.formattedAmount(b2ccod),
                b2b_c2c: this.formattedAmount(b2bc2c),
                b2b_retail: this.formattedAmount(b2bretail),
                fc_invoice: this.formattedAmount(fcinvoice),
                skynet_int: this.formattedAmount(skynetint),
                e2e_services_invoice: this.formattedAmount(e2eserviceinvoice),
                ftl_services: this.formattedAmount(ftlservices),
                hkd_skynet: this.formattedAmount(hkdskynet),
                not_due: this.formattedAmount(notdue),
                zero_to_thirty: this.formattedAmount(zerotothirty),
                thirtyone_to_sixty: this.formattedAmount(thirtyonetosixty),
                sixtyone_to_ninety: this.formattedAmount(sixtyonetoninety),
                ninetyone_to_onetwenty: this.formattedAmount(
                  ninetyonetoonetwenty
                ),
                onetwnentyone_to_oneeighty: this.formattedAmount(
                  onetwentyonetooneeighty
                ),
                oneeightyone_to_threesixtyfive: this.formattedAmount(
                  oneeightytothreesixtyfice
                ),
                more_than_threesixtyfive: this.formattedAmount(
                  morethanthreesixtyfive
                )
              }
            ];
          }
          this.loader = false;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideTotalTable() {
      // this function shows the table that contains the total of all the records
      if (this.loader) {
        alert('Please wait, total is being calculated');
      } else {
        this.totalAmountFlag = !this.totalAmountFlag;
      }
    },
    selectBusinessMethod() {
      // this function stores the selected business modal into businessMethodModal variable
      this.businessMethodModal = !this.businessMethodModal;
    },
    selectAllBoxChecked(flag) {
      // this function selects all the records and stores them inside the businessMethodModalData array
      this.selectedBusinessMethod = [];
      if (flag) {
        this.businessMethodModalData = this.businessMethodModalData.map(
          (data, index) => {
            data.selectBox = this.selectAllCheckBox;
            if (this.selectAllCheckBox) {
              this.selectBoxChecked(data.selectBox, index);
            }
            return data;
          }
        );
        this.selectedRow = this.businessMethodModalData;
      } else {
        this.businessMethodModalData = this.businessMethodModalData.map(
          (data, index) => {
            data.selectBox = this.selectAllCheckBox;
            if (this.selectAllCheckBox) {
              this.selectBoxChecked(data.selectBox, index);
            }
            return data;
          }
        );
      }
    },
    selectBoxChecked(flag, index, item, businessMethod) {
      // this function selects the checked record and then stores that particular record inside selectedBusinessMethod array
      this.businessMethodModalData[index].selectBox = flag;
      if (flag === true) {
        this.selectedBusinessMethod.push(item.business_method);
      } else {
        this.selectedBusinessMethod = this.selectedBusinessMethod.filter(
          el => el !== businessMethod
        );
      }
    },
    selectedRadio() {
      // this function selects a particular record from the main table and if any single record is selected, it shows the View Dispute button
      this.selectRadio = true;
    },
    downloadPage() {
      // this function downloads the data of a single page and then converts that data into an excel sheet and then downloads that excel sheet by default
      const payload = {
        _page: 0,
        _limit: this.perPage,
        legal_entity_id: this.legalEntity.value,
        // order_by: this.orderBy,
        // order_name: this.orderName,
        customer_name: this.customerName,
        customer_number: this.customerNumber,
        amount_from: this.amountFrom,
        amount_to: this.amountTo
      };
      this.loader = true;
      this.downloadingData = [];
      this.$store
        .dispatch('customerBalances/getCustomerBalances', payload)
        .then(response => {
          if (response.status === 200) {
            const downloaddata = response.data.data.data.page;
            for (let i of downloaddata) {
              let sequencedData = {
                le_name: i.le_name,
                customer_number: +i.customer_number,
                customer_name: i.customer_name,
                total_amount_due: +i.total_amount_due,
                pmt_amount_due: +i.pmt_amount_due,
                inv_amount_due: +i.inv_amount_due,
                cm_amount_due: +i.cm_amount_due,
                dm_amount_due: +i.dm_amount_due,
                others: +i.others,
                b2b_postpaid: +i.b2b_postpaid,
                b2b_prepaid: +i.b2b_prepaid,
                b2c_prepaid: +i.b2c_prepaid,
                b2c_postpaid: +i.b2c_postpaid,
                b2c_cod_netoff: +i.b2c_cod_netoff,
                b2b_c2c: +i.b2b_c2c,
                skynet_int: +i.skynet_int,
                b2b_retail: +i.b2b_retail,
                fc_invoice: +i.fc_invoice,
                e2e_services_invoice: +i.e2e_services_invoice,
                ftl_services: +i.ftl_services,
                hkd_skynet: +i.hkd_skynet,
                not_due: +i.not_due,
                zero_to_thirty: +i.zero_to_thirty,
                thirtyone_to_sixty: +i.thirtyone_to_sixty,
                sixtyone_to_ninety: +i.sixtyone_to_ninety,
                ninetyone_to_onetwenty: +i.ninetyone_to_onetwenty,
                onetwnentyone_to_oneeighty: +i.onetwnentyone_to_oneeighty,
                oneeightyone_to_threesixtyfive: +i.oneeightyone_to_threesixtyfive,
                more_than_threesixtyfive: +i.more_than_threesixtyfive
              };
              this.downloadingData.push(sequencedData);
            }
            const ws = XLSX.utils.json_to_sheet(this.downloadingData);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Customer Balances Data');
            XLSX.writeFile(wb, 'customer_balances.xlsx');
          }
          this.loader = false;
        })
        .catch(() => {
          this.loader = false;
        });
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
