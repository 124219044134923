import dispute from '../dispute';
import commonHelper from '@/app/utility/common.helper.utility';

export default {
  name: 'viewDisputes',
  props: ['searchDisputeParams'],
  components: {
    dispute
  },
  data() {
    return {
      legalEntity: {
        value: '',
        text: ''
      },
      customerName: '',
      customerCode: '',
      transactionNumber: '',
      disputesData: [],
      disputesFields: [
        {
          key: 'dispute_hdr_id',
          label: 'Dispute Number',
          class: 'fix-amount text-center'
        },
        {
          key: 'dispute_amount',
          class: 'fix-amount text-right'
        },
        {
          key: 'created_by',
          label: 'Raised By',
          class: 'fix-amount text-center'
        },
        {
          key: 'creation_date',
          label: 'Raised Date',
          class: 'fix-amount text-center'
        },
        {
          key: 'dispute_status',
          class: 'fix-amount text-center'
        }
      ],
      showOpenDisputeModal: false,
      searchDisputeViewParams: {},
    };
  },
  mounted() {
    if (this.searchDisputeParams) {
      this.getParentToChildData(this.searchDisputeParams);
      this.getDisputes();
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.searchDisputeViewParams = {
            legalEntity : this.legalEntity,
            customerName : this.customerName,
            customerCode : this.customerCode,
            transactionNumber : this.transactionNumber
          }
          this.showOpenDisputeModal = true;
        }
      }
    });
  },
  methods: {
    formattedAmount(num) {
      return commonHelper.formatCurrencyAmount(num);
    },
    getParentToChildData(item) {
      this.legalEntity = item.legalEntity;
      this.customerName = item.customerName;
      this.customerCode = item.customerCode;
      this.transactionNumber = item.transactionNo;
    },
    disputeModal(flag) {
      this.showOpenDisputeModal = flag;
    },
    getDisputes() {
      const payload = {
        _page: 0,
        _limit: 1000,
        trx_num : this.transactionNumber
      };
      this.loading = true;
      this.$store
        .dispatch('customerBalances/customerBalancesDisputeData', payload)
        .then(response => {
          if (response.status === 200) {
            this.disputesData = response.data.data.page;
            for (let i of this.disputesData) {
              if (i.dispute_status === 'OP') {
                i.dispute_status = 'OPEN';
              }
            }
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    rowSelected(item) {
      this.showOpenDisputeModal = true;
      this.searchDisputeViewParams = {
        legalEntity : this.legalEntity,
        customerName : this.customerName,
        customerCode : this.customerCode,
        transactionNumber : this.transactionNumber,
        disputeHdrId: item.dispute_hdr_id,
        transactionId: this.searchDisputeParams.transactionId,
        transactionNo: this.searchDisputeParams.transactionNo,
        glDate: this.searchDisputeParams.glDate,
        operatingUnit: this.searchDisputeParams.operatingUnit
      }
    },
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
