  import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import responseModal from '../../../../shared/responseModal';
import { format } from 'date-fns';
import DatePicker from 'vue2-datepicker';

export default {
  name: 'customerBalancesDetailsData',
  watch: {
    currentPage: function() {
      this.getCustomerBalancesDetailsData();
    },
    modalCurrentPage: function() {
      this.gstTransactionNumber();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getCustomerBalancesDetailsData();
    },
    customerBalancesMoreData: function() {
      this.selectedTransactionNumber();
    }
  },
  // computed: {
  //   modalTotalRows() {
  //     return 3000;
  //   }
  // },
  components: {
    responseModal,
    DatePicker
  },
  props: ['requestParams'],
  data() {
    return {
      loading: false,
      currentPage: 1,
      perPage: 1000,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      modalTotalRows: null,
      modalCurrentPage: 1,
      totalApplied: 0,
      totalUnapplied: 0,
      transactionId: '',
      transactionNumber: '',
      transactionPayload: null,
      customerBalancesDetailsDataData: [],
      customerBalancesDetailsDataFields: [
        {
          key: 'operating_unit',
          class: 'col-fix text-center'
        },
        {
          key: 'receipt_method',
          class: 'col-fix-name text-center'
        },
        {
          key: 'customer_name',
          class: 'col-fix-name text-center'
        },
        {
          key: 'customer_number',
          class: 'col-fix text-center'
        },
        {
          key: 'customer_site',
          class: 'col-fix text-center'
        },
        {
          key: 'currency_code',
          class: 'col-fix text-center'
        },
        {
          key: 'amount',
          class: 'fix-amount text-right'
        },
        {
          key: 'inr_amount',
          label:'INR Amount',
          class: 'fix-amount text-right'
        },
        {
          key: 'trx_number',
          label: 'Transaction Number',
          class: 'col-fix text-center'
        },
        {
          key: 'trx_date',
          label: 'Transaction Date',
          class: 'col-fix text-center'
        }
      ],
      customerBalancesMoreData: [],
      customerBalancesMoreDataSearch: [],
      customerBalancesMoreFields: [
        { key: 'add_receipt', label: 'Add', stickyColumn: true },
        {
          key: 'selectRadio',
          label: 'Select',
          stickyColumn: true,
          class: 'selectRadio',
          variant: 'primary'
        },
        {
          key: 'apply_to',
          class: 'col-fix text-center'
        },  
        {
          key: 'trx_class',
          class: 'col-fix text-center'
        },
        {
          key: 'currency_code',
          class: 'col-fix text-center'
        },
        {
          key: 'amount_applied',
          label: 'Applied Amount',
          class: 'fix-amount text-right'
        },
        {
          key: 'applied_inr_amount',
          label: 'Applied INR Amount',
          class: 'fix-amount text-right'
        },
        {
          key: 'apply_date',
          class: 'col-fix text-center'
        },
        {
          key: 'gl_date',
          label: 'GL Date',
          class: 'col-fix text-center'
        }
      ],
      radio: null,
      unapplyFlag: false,
      unapplyData: {},
      isSuccess: false,
      responseMsg: '',
      showAlert: false,
      applyTo: '',
      addNewReceiptFlag: true,
      showOpenTransactionNumber: false,
      transactionNumberData: [],
      transactionNumberFields: [
        {
          key: 'amount_due'
        },
        {
          key: 'amount_original'
        },
        {
          key: 'customer_trx_id'
        },
        {
          key: 'trx_number'
        },
        {
          key: 'trx_type'
        }
      ],
      customerId: '',
      transactionNumbers: {
        amountDue: '',
        amountOriginal: '',
        customerTrxId: '',
        transactionNumber: '',
        trxType: ''
      },
      applyToInput: null,
      applyClassInput: null,
      appliedAmountInput: null,
      applyDateInput: format(new Date(), appStrings.DATEFNSFORMAT),
      glDateInput: format(new Date(), appStrings.DATEFNSFORMAT),
      transactionIdInput: null,
      amountDue: null,
      customerBalancesMoreInputData: [],
      customerBalancesMoreInputFields: [
        { key: 'add_receipt', label: 'Add', stickyColumn: true },
        {
          key: 'selectRadio',
          label: 'Select',
          stickyColumn: true,
          class: 'selectRadio',
          variant: 'primary'
        },
        {
          key: 'apply_to',
          class: 'col-fix text-center'
        },
        {
          key: 'trx_class',
          class: 'col-fix text-center'
        },
        {
          key: 'currency_code',
          class: 'col-fix text-center'
        },
        {
          key: 'applied_amount',
          label: 'Applied Amount',
          class: 'fix-amount text-right'
        },
        {
          key: 'applied_inr_amount',
          label: 'Applied INR Amount',
          class: 'fix-amount text-right'
        },
        {
          key: 'apply_date',
          class: 'col-fix text-center'
        },
        {
          key: 'gl_date',
          label: 'GL Date',
          class: 'col-fix text-center'
        }
      ],
      editMode: false
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'edit' || actionName === 'update') {
          this.editMode = true;
        }
        if(actionName === 'save') {
          this.applyReceipt();
        }
      }
    });
    this.transactionId = this.requestParams.trx_id;
    this.transactionNumber = this.requestParams.trx_number;
    this.customerId = this.requestParams.customer_id;
    this.getCustomerBalancesDetailsData();
  },
  methods: {
    downloadExcelData() {
      this.loading = true;
      const downloadpayload = { ...this.downloadPayload };
      downloadpayload._limit = this.totalRows;
      this.downloadExcel.downloadData(
        downloadpayload,
        'customerBalances/getCustomerBalancesReceipt',
        'customer-balances-details-data',
        () => (this.loading = false),
        this.customerBalancesDetailsData
      );
    },
    formattedAmount(num) {
      return commonHelper.formatAmountWithoutCurrency(num);
    },
    formattedAmountWithoutCurrency(num) {
      num = +(+num).toFixed(2);
      num = num.toLocaleString('en-IN', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
      return num;
    },
    getCustomerBalancesDetailsData() {
      if (this.requestParams.class_type === 'Receipt') {
        this.customerBalancesDetailsDataFields.pop();
        this.customerBalancesDetailsDataFields.pop();
        this.customerBalancesDetailsDataFields.push({
          key: 'receipt_number',
          label: 'Receipt Number',
          class: 'col-fix text-center'
        });
        this.customerBalancesDetailsDataFields.push({
          key: 'receipt_date',
          label: 'Receipt Date',
          class: 'col-fix text-center'
        });
        const payload = {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          cash_receipt_id: this.transactionId
        };
        this.downloadPayload = {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          cash_receipt_id: this.transactionId
        };
        this.loading = true;
        this.totalApplied = 0;
        this.totalUnapplied = 0;
        this.$store
          .dispatch('customerBalances/getCustomerBalancesReceipt', payload)
          .then(response => {
            if (response.status === 200) {
              this.customerBalancesDetailsDataData = [];
              this.customerBalancesDetailsDataData.push(
                response.data.data.data.page[0]
              );
              this.customerBalancesMoreData = response.data.data.data.page;
              this.customerBalancesMoreDataSearch = response.data.data.data.page;
              this.totalRows = response.data.data.data.total_elements;
              for (let i of this.customerBalancesMoreData) {
                this.totalApplied =
                  +this.totalApplied + +i.amount_applied;
                if (i.trx_class === 'CM') {
                  i.trx_class = 'Credit Memo';
                } else if (i.trx_class === 'PMT') {
                  i.trx_class = 'Receipt';
                } else if (i.trx_class === 'INV') {
                  i.trx_class = 'Invoice';
                } else if (i.trx_class === 'DM') {
                  i.trx_class = 'Debit Memo';
                }
              }
              this.totalUnapplied =
                Math.abs(this.customerBalancesDetailsDataData[0].amount) -
                this.totalApplied;
            }
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      } else if (this.requestParams.class_type === 'Credit Memo') {
        this.customerBalancesDetailsDataFields.splice(1, 1);
        this.customerBalancesMoreFields.splice(0, 1);
        this.customerBalancesMoreFields[4].key = 'amount_applied';
        const payload = {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          customer_trx_id: this.transactionId
        };
        this.downloadPayload = {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          customer_trx_id: this.transactionId
        };
        this.loading = true;
        this.totalApplied = 0;
        this.totalUnapplied = 0;
        this.$store
          .dispatch('customerBalances/getCustomerBalancesCreditMemo', payload)
          .then(response => {
            if (response.status === 200) {
              this.customerBalancesDetailsDataData = [];
              this.customerBalancesDetailsDataData.push(
                response.data.data.data.page[0]
              );
              this.customerBalancesMoreData = response.data.data.data.page;
              this.customerBalancesMoreDataSearch = response.data.data.data.page;
              this.totalRows = response.data.data.data.total_elements;
              for (let i of this.customerBalancesMoreData) {
                this.totalApplied = +this.totalApplied + +i.amount_applied;
                if (i.trx_class === 'CM') {
                  i.trx_class = 'Credit Memo';
                } else if (i.trx_class === 'PMT') {
                  i.trx_class = 'Receipt';
                } else if (i.trx_class === 'INV') {
                  i.trx_class = 'Invoice';
                } else if (i.trx_class === 'DM') {
                  i.trx_class = 'Debit Memo';
                }
              }
              this.totalUnapplied =
                Math.abs(this.customerBalancesDetailsDataData[0].amount) -
                this.totalApplied;
            }
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      } else if (
        this.requestParams.class_type === 'Invoice' ||
        this.requestParams.class_type === 'Debit Memo'
      ) {
        this.customerBalancesDetailsDataFields.splice(1, 1);
        this.customerBalancesMoreFields.splice(0, 1);
        this.customerBalancesMoreFields[4].key = 'amount_applied';
        const payload = {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          customer_trx_id: this.transactionId
        };
        this.downloadPayload = {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          customer_trx_id: this.transactionId
        };
        this.loading = true;
        this.totalApplied = 0;
        this.totalUnapplied = 0;
        this.$store
          .dispatch('customerBalances/getCustomerBalancesInvoice', payload)
          .then(response => {
            if (response.status === 200) {
              this.customerBalancesDetailsDataData = [];
              this.customerBalancesDetailsDataData.push(
                response.data.data.data.page[0]
              );
              this.customerBalancesMoreData = response.data.data.data.page;
              this.customerBalancesMoreDataSearch = response.data.data.data.page;
              this.totalRows = response.data.data.data.total_elements;
              for (let i of this.customerBalancesMoreData) {
                this.totalApplied = +this.totalApplied + +i.amount_applied;
                if (i.trx_class === 'CM') {
                  i.trx_class = 'Credit Memo';
                } else if (i.trx_class === 'PMT') {
                  i.trx_class = 'Receipt';
                } else if (i.trx_class === 'INV') {
                  i.trx_class = 'Invoice';
                } else if (i.trx_class === 'DM') {
                  i.trx_class = 'Debit Memo';
                }
              }
              this.totalUnapplied =
                Math.abs(this.customerBalancesDetailsDataData[0].amount) -
                this.totalApplied;
            }
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    truncateComment(souce, size) {
      return commonHelper.truncate(souce, size);
    },
    selectedRadio(item) {
      this.unapplyFlag = true;
      this.unapplyData = {};
      if (this.requestParams.class_type === 'Receipt') {
        this.unapplyData = {
          P_TRX_ID: this.requestParams.trx_id,
          P_PAYMENT_SCHEDULE_ID: item.applied_payment_schedule_id,
          P_REC_APPLICATION_ID: item.receivable_application_id,
          P_ORG_ID: item.org_id,
          P_TYPE: item.status,
          P_CLASS: 'PMT'
        };
      } else {
        this.unapplyData = {
          P_TRX_ID: this.requestParams.trx_id,
          P_PAYMENT_SCHEDULE_ID: item.applied_payment_schedule_id,
          P_REC_APPLICATION_ID: item.receivable_application_id,
          P_ORG_ID: item.org_id,
          P_TYPE: item.status,
          P_CLASS: item.trx_class
        };
      }
    },
    unapply() {
      this.loading = true;
      this.$store
        .dispatch('customerBalances/customerBalancesUnapply', this.unapplyData)
        .then(response => {
          if (response.status === 200) {
            this.getCustomerBalancesDetailsData();
            this.responseMsg = response.data.data.data.message;
            this.makeToast(this.responseMsg, 'success');
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.isSuccess = false;
          this.showAlert = true;
          this.responseMsg = appStrings.autoFailedMsg;
          this.makeToast(this.responseMsg, 'danger');
        });
    },
    applyToSearch(apply) {
      if (apply !== '') {
        this.customerBalancesMoreData = this.customerBalancesMoreData.filter(
          item => {
            return item.apply_to === apply;
          }
        );
      } else {
        this.customerBalancesMoreData = this.customerBalancesMoreDataSearch;
      }
    },
    addNewRow() {
      this.addNewReceiptFlag = false;
      this.applyToInput = '';
      this.applyClassInput = '';
      this.transactionIdInput = '';
      this.amountDue = '';
      this.appliedAmountInput = '';
      this.customerBalancesMoreInputData.push({
        selectRadio: null,
        add_receipt: null,
        apply_to: null,
        trx_class: null,
        currency_code: this.customerBalancesMoreData.currency_code,
        acctd_amount_applied_from: null,
        apply_date: format(new Date(), appStrings.DATEFNSFORMAT),
        gl_date: format(new Date(), appStrings.DATEFNSFORMAT)
      });
    },
    openCloseResponseModal(item) {
      if (item === 'Transaction Number') {
        this.gstTransactionNumber();
        this.showOpenTransactionNumber = true;
      }
    },
    transactionNumberModal(flag) {
      this.showOpenTransactionNumber = flag;
    },
    selectedTransactionNumber(flag, transactionNumber) {
      this.showOpenTransactionNumber = flag;
      this.transactionNumbers = transactionNumber;
    },
    gstTransactionNumber() {
      const payload = {
        bill_to_customer_id: this.customerId,
        org_id: this.customerBalancesDetailsDataData[0].org_id,
        _page: this.modalCurrentPage - 1,
        _limit: 10
      };
      this.loading = true;
      this.$store
        .dispatch('customerBalances/customerBalancesTransactionNumber', payload)
        .then(response => {
          if (response.status === 200) {
            this.transactionNumberData = response.data.data.data.page;
            this.modalTotalRows = response.data.data.data.total_elements;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    applyReceipt() {
      if (
        this.applyToInput === 'WRITEOFF' ||
        this.applyToInput === 'ONACCOUNT'
      ) {
        if (this.appliedAmountInput > this.totalUnapplied) {
          alert(
            'Applied Amount is greater than Total Unapplied Amount! Please Check.'
          );
        } else {
          this.apply();
        }
      } else if (
        this.applyToInput !== 'WRITEOFF' &&
        this.applyToInput !== 'ONACCOUNT'
      ) {
        if (
          this.appliedAmountInput > this.totalUnapplied &&
          this.appliedAmountInput > this.amountDue
        ) {
          alert(
            'Applied Amount is greater than Total Unapplied Amount or Applied Amount is greater than Total Unapplied Amount! Please Check.'
          );
        } else {
          this.apply();
        }
      }
    },
    apply() {
      const payload = {
        cash_receipt_id: this.transactionId,
        Attributes: [
          {
            ou_id: this.customerBalancesDetailsDataData[0].org_id,
            transaction_id: this.transactionIdInput,
            transaction_num: this.applyToInput,
            transaction_type: this.applyClassInput,
            applied_amount: this.appliedAmountInput,
            gl_date: this.glDateInput,
            apply_date: this.applyDateInput
          }
        ]
      };
      this.loading = true;
      this.$store
        .dispatch('customerBalances/customerBalancesApplyReceipt', payload)
        .then(response => {
          if (response.status === 200) {
            this.isSuccess = true;
            this.showAlert = true;
            this.responseMsg = response.data.data.data.po_message;
            this.makeToast(this.responseMsg, 'success');
            if (this.responseMsg[8] === 'S') {
              this.getCustomerBalancesDetailsData();
            }
            this.customerBalancesMoreInputData = [];
            this.addNewReceiptFlag = true;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.isSuccess = false;
          this.showAlert = true;
          this.responseMsg = appStrings.autoFailedMsg;
          this.makeToast(this.responseMsg, 'danger');
        });
    },
    makeToast(content, variant) {
      this.$bvToast.toast(content, {
        title: 'Alert',
        variant: variant,
        solid: true
      });
    },
  },
  created() {
    this.eventBus.$on('transactionNumbers', transactionNumbers => {
      this.transactionNumbers = transactionNumbers;
      this.applyToInput = this.transactionNumbers.transactionNumber;
      this.applyClassInput = this.transactionNumbers.trxType;
      this.transactionIdInput = this.transactionNumbers.customerTrxId;
      this.amountDue = this.transactionNumbers.amountDue;
    });
  },
  beforeDestroy() {
    this.eventBus.$off('transactionNumbers');
    this.unsubscribe();
  }
};
