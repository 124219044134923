import commonHelper from '@/app/utility/common.helper.utility';
import responseModal from '../../../../shared/responseModal';
import appStrings from '@/app/utility/string.utility';
import DatePicker from 'vue2-datepicker';
import { format } from 'date-fns';
import { ModelSelect } from 'vue-search-select';

export default {
  name: 'createReceipt', // name of this component
  components: {
    responseModal,
    DatePicker,
    ModelSelect
  },
  watch: {
    modalCurrentPage: function() {
      this.gstTransactionNumber();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getOutstandingData();
    },
  },
  data() {
    return {
      loading: false, // it keeps the state of loading that is when any API is called, oading is set to true and when it is done, loading is set to false
      vsetCode: null, // it keeps the state for vsetcode for value set modals
      showValueSetModal: false, // it keeps the boolean state for value set modal
      parent_value_set_id: null, // it keeps the value forn parentvalueset in value set modals
      depVsetParam: null,
      showReceiptPreview: false, // it keeps the state of show receipt
      previousPageData: [], // it keeps an array that has all the values that comes from previous page with the help of store
      showOpenLegalEntity: false, // it keeps the state of the response modal for legal entity input form
      legalEntity: {
        // it keeps the state of Legal Entity input form
        value: null,
        text: null
      },
      showOpenOperatingUnit: false, // it keeps the state of the response modal for operating unit input form
      operatingUnit: {
        // it keeps the state of Operating Unit input form
        value: null,
        text: null
      },
      defaultValue: {
        // it keeps the state for nothing, but it changes the state of other input forms if clicked on cross button
        value: null,
        text: null
      },
      currentPage: 1, // it keeps the state of current page and is by default set to 1
      customerPerPage: commonHelper.customerPerPageRecord, // it keeps the state of the per page records
      legalEntityData: [], // it keeps the array that stores the data that comes in response from legal entity api
      legalEntityFields: [
        // it keeps the array that stores the fields that comes in response from legal entity api
        {
          key: 'legal_entity_id'
        },
        {
          key: 'name'
        }
      ],
      operatingUnitData: [], // it keeps the array that stores the data that comes in response from operating unit api
      operatingUnitFields: [
        // it keeps the array that stores the fields that comes in response from operating unit api
        {
          key: 'name'
        }
      ],
      totalRows: null, // it keeps the state of the total rows that comes in response from the api's,
      customerId: '', // it keeps the state of customer id that comes from previous page
      organizationId: '', // it keeps the state of organization id that comes from previous page
      selectReceiptNature: {
        // it keeps the state of select receipt nature
        value: 'identified',
        text: 'Identified'
      },
      customerName: '', // it keeps the state for Customer Name input form
      customerSite: {
        // it keeps the state for Customer Site input form
        value: null,
        text: null,
        meaning: null
      },
      showOpenCustomerSite: false, // it keeps the boolean state for Customer Site Modal
      customerSiteFields: [
        // it keeps the array that stores the fields that comes in response from customer site api
        {
          key: 'party_site_number'
        },
        {
          key: 'gst_number'
        }
      ],
      customerSiteData: [], // it keeps the array that stores the data that comes in response from customer site api
      costomerDetailsVisible: true, // it keeps the state for if the tab for customer details is active or not
      receiptNumber: '', // it keeps the state for Receipt Number input form
      currency: {
        // it keeps the state for Currency input fom
        value: null,
        text: null
      },
      showOpenCurrency: false, // it keeps the boolean state for Currency Modal
      currencyFields: [
        // it keeps an array that stores the fields that comes from response of Currency API
        {
          key: 'currency_code'
        },
        {
          key: 'name'
        }
      ],
      currencyData: [], // it keeps an array that stores the data that comes from response of Currency API
      exchangeType: null, // it keeps the state for Exchange Type input form and the initial state is set to 'Corporate'
      exchangeRate: 1, // it keeps the state for Exchange Rate input form and the initial state is set to '1'
      selectedPaymentMode: {
        // it keeps the state for Payment Mode input form which is a value set form
        value: null,
        text: null
      },
      instrumentNum: null, // it keeps the state for the Ins. Num input form
      instrument_date: format(new Date(), appStrings.DATEFNSFORMAT), // it keeps the state for the Ins. Date input form, by default it is set to current date
      amount: null, // it keeps the state for Amount input form
      value_date: format(new Date(), appStrings.DATEFNSFORMAT), // it keeps the state for the Value Date input form, by default it is set to current date
      gl_date: format(new Date(), appStrings.DATEFNSFORMAT), // it keeps the state for the GL Date input form, by default it is set to current date
      narration: null, // it keeps the state for the Narration input form
      selectedBank: {
        // it keeps the state for the Selecetd Bank input form
        value: null,
        text: null
      },
      branch: null, // it keeps the state for the Branch Name input form
      instrumentStatus: null, // it keeps the state for the Status input form
      clearingDate: null, // it keeps the state for the Clearing Date input form
      reason: null, // it keeps the state for the Reason input form
      reversalComments: null, // it keeps the state for the Reversal Comments input form
      tdsAmount: null, // it keeps the state for the TDS Amount input form
      tdsReceiptMethod: {
        // it keeps the state for the TDS Receipt Method input form
        text: null,
        value: null
      },
      tdsReceiptMethodList: [], // it keeps the state for the TDS Method List input form
      tdsReceiptNo: null, // it keeps the state for the TDS Receipt Number input form
      autoApply: true, // it keeps the boolean state for the checkbox in the receipt method tab
      showOpenReceiptMethod: false, // it keeps the state for the Receipt Method b-modal
      receiptMethod: {
        // it keeps the state for the receipt type tab
        accountname: '',
        accountnumber: '',
        bankname: '',
        bankbranchname: '',
        receiptmethod: '',
        receiptmethodid: ''
      },
      receiptMethodData: [], // this array keeps the data that comes in response by the Receipt Method API
      receiptMethodFields: [
        // this array keeps the fields that comes in response by the Receipt Method API
        {
          key: 'receipt_method'
        }
      ],
      showAdjWarning: false, // it keeps the boolean state for whether to show the alert message or not
      warningMessage: null, // it keeps the state for the warning message to be displayed on screen
      selectedApplicationType: {
        // it keeps the state for the Application Type input form
        value: null,
        text: null
      },
      applicationTypeList: [], // it keeps the state for the Application Type List
      remainingAmout: 0.0, // it keeps the state for the Remaining Amount input form
      totalOsAmout: null, // it keeps the state for the Total Aoutstanding Amount input form
      outstandingData: [
        // this array keeps the data to be kept in outstanding table
        {
          trx_number: '',
          amount_original: '',
          amount_due: '',
          gl_date: format(new Date(), appStrings.DATEFNSFORMAT),
          apply_date: format(new Date(), appStrings.DATEFNSFORMAT),
          class_type: '',
          transaction_id: ''
        }
      ], // this array keeps the data that comes from the previous page for invoice and DM only
      outStandingTableFields: [
        // this array keeps the fields that comes from the previous page for invoice and DM only
        { key: 'add_invoice', label: 'Add', stickyColumn: true },
        { key: 'remove_invoice', label: 'Remove', stickyColumn: true },
        {
          key: 'trx_number'
        },
        {
          key: 'class_type'
        },
        {
          key: 'amount_original'
        },
        {
          key: 'amount_due'
        },
        {
          key: 'applied_amount'
        },
        {
          key: 'gl_date'
        },
        {
          key: 'apply_date'
        }
      ],
      advanceAmountWarning: false, // it keeps the boolean state for if the Advance Amount exist or not
      adJustmentDataList: [
        // this array keeps the data for the Adjustment Data List table
        {
          category_name: null,
          receipt_hdr_id: null,
          tax_cat_id: null,
          receipt_adv_id: 0,
          amount: 0.0
        }
      ],
      adjustmentTableFields: [
        // this array keeps the fields for the Adjustment Data List table
        {
          key: 'category_name',
          label: 'Tax Category'
        },
        {
          key: 'total_amount'
        },
        {
          key: 'amount'
        },
        {
          key: 'tax_amount'
        }
      ],
      showTaxCategoryFlag: false, // it keeps the boolean state for the Open Tax Category Modal
      responseMsg: '', // it keeps the state for the response message to be delivered after API calling
      receiptMethodId: '', // it keeps the state for the receipt method id that comes in response of the API called on click of receipt method input form
      showOpenTransactionNumber: false,
      transactionNumberData: [],
      transactionNumberFields: [
        {
          key: 'amount_due'
        },
        {
          key: 'amount_original'
        },
        {
          key: 'customer_trx_id'
        },
        {
          key: 'trx_number'
        },
        {
          key: 'trx_type'
        }
      ],
      modalTotalRows: null,
      modalCurrentPage: 1,
      perPage: commonHelper.perPageRecordData,
      pageOptions: commonHelper.getPageOption(),
      transactionNumbers: {
        amountDue: '',
        amountOriginal: '',
        customerTrxId: '',
        transactionNumber: '',
        trxType: ''
      },
      applyToInput: null,
      applyClassInput: null,
      appliedAmountInput: null,
      applyDateInput: null,
      glDateInput: null,
      outstandingIndex: null, // it keeps the index of the element in which the data is being entered inside the outstanding table
      outstandingPayload: [],
      totalAppliedAmount: null, // it keeps the state for the total of all the amount applied in the outstanding table
      poReceiptId: null, // it keeps the state for the receipt id generated on successful request of create receipt and passed as a payload in apply receipt
      editMode: false, // it keeps the boolean state for edit mode
      editFlag: false
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && !this.showOpenDisputeModal) {
          this.showReceiptPreview = true;
        } if (actionName === 'update') {
          this.editFlag = true;
        }   
        // if (actionName === 'save' && this.receiptHdrId) {
        //   this.addEditReceiptNotes();
        // }
      }
    });
    this.previousPageData = this.$store.state.customerBalances.customerBalancesDetailsData;
    this.getParentToChildData();
    // this.getOutstandingData();
  },
  methods: {
    getParentToChildData() {
      // this function gets the values that comes from store and assigns them to the desired variables
      this.customerName = this.previousPageData[0].customer_name;
      this.legalEntity = {
        value: this.previousPageData[0].legal_entity_id,
        text: this.previousPageData[0].le_name
      };
      this.operatingUnit = {
        value: this.previousPageData[0].organization_id,
        text: this.previousPageData[0].name
      };
      this.customerId = this.previousPageData[0].customer_id;
      this.organizationId = this.previousPageData[0].organization_id;
    },
    openCloseResponseModal(item, index) {
      // this function handles the opening and closing of the input forms that are custom response modal and then assigns the value to those particular input forms
      this.outstandingIndex = index;
      if (item === 'Legal Entity') {
        this.showOpenLegalEntity = true;
        this.operatingUnit = this.defaultValue;
        this.getLegalEntityData();
      } else if (item === 'Operating Unit') {
        if (this.legalEntity.value) {
          this.showOpenOperatingUnit = true;
          this.getOperatingUnitData();
        } else {
          alert('Please select Legal Entity');
        }
      } else if (item === 'Customer Site') {
        this.showOpenCustomerSite = true;
        this.getCustomerSite();
      } else if (item === 'Currency') {
        this.showOpenCurrency = true;
        this.getCurrency();
      } else if (item === 'Receipt Method') {
        this.showOpenReceiptMethod = true;
        this.getReceiptMethod();
      } else if (item === 'Transaction Number') {
        this.showOpenTransactionNumber = true;
        this.gstTransactionNumber();
      }
    },
    clearSearchParams(params) {
      // this functions clears the values of these particular input forms on click of cross button
      if (params === 'Legal Entity') {
        this.legalEntity = this.defaultValue;
      } else if (params === 'Operating Unit') {
        this.operatingUnit = this.defaultValue;
      }
    },
    getLegalEntityData() {
      // this function gets the response as values in the custom response modal for selection of Legal Entity input form
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.customerPerPage
      };
      this.loading = true;
      this.$store
        .dispatch('collectionDashboard/getLegalEntity', payload)
        .then(response => {
          if (response.status === 200) {
            this.legalEntityData = response.data.data.data.page;
            this.totalRows = response.data.data.data.total_elements;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    legalEntityModal(flag) {
      // this function sets and changes the state of the modal that opens on click of Legal Entity input form
      this.showOpenLegalEntity = flag;
    },
    selectedLegalEntity(flag, legalEntity) {
      // this function is passed as a callback to the response modal for Legal Entity input form when any value is selected in response modal, this function collects the value and assigns it to the desired variable
      this.showOpenLegalEntity = flag;
      this.legalEntity = legalEntity;
    },
    getOperatingUnitData() {
      // this function gets the response as values in the custom response modal for selection of Operating Unit input form
      const payload = {
        default_legal_context_id: this.legalEntity.value,
        _page: this.currentPage - 1,
        _limit: this.customerPerPage
      };
      this.loading = true;
      this.$store
        .dispatch('collectionDashboard/getOperatingUnit', payload)
        .then(response => {
          if (response.status === 200) {
            this.operatingUnitData = response.data.data.data.page;
            this.totalRows = response.data.data.data.total_elements;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    operatingUnitModal(flag) {
      // this function sets and changes the state of the modal that opens on click of Operating Unit input form
      this.showOpenOperatingUnit = flag;
    },
    selectedOperatingUnit(flag, operatingUnit) {
      // this function is passed as a callback to the response modal for Operating Unit input form when any value is selected in response modal, this function collects the value and assigns it to the desired variable
      this.showOpenOperatingUnit = flag;
      this.operatingUnit = operatingUnit;
    },
    getCustomerSite() {
      // this function gets the response as values in the custom response modal for selection of Customer Site input form
      const payload = {
        cust_account_id: this.customerId,
        org_id: this.organizationId,
        _page: this.currentPage - 1,
        _limit: 10
      };
      this.loading = true;
      this.$store
        .dispatch('customerBalances/customerBalancesCustomerSite', payload)
        .then(response => {
          if (response.status === 200) {
            this.customerSiteData = response.data.data.data.page;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    customerSiteModal(flag) {
      // this function sets and changes the state of the modal that opens on click of Customer Site input form
      this.showOpenCustomerSite = flag;
    },
    selectedCustomerSite(flag, customersite) {
      // this function is passed as a callback to the response modal for Customer Site input form when any value is selected in response modal, this function collects the value and assigns it to the desired variable
      this.showOpenCustomerSite = flag;
      this.customerSite = customersite;
    },
    getCurrency() {
      // this function gets the data that comes in response by the currency API
      const payload = {
        _page: this.currentPage - 1,
        _limit: 200
      };
      this.loading = true;
      this.$store
        .dispatch('customerBalances/customerBalancesCurrency', payload)
        .then(response => {
          if (response.status === 200) {
            this.currencyData = response.data.data.data.page;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    currencyModal(flag) {
      // this function sets and changes the state of the modal that opens on click of Currency input form
      this.showOpenCurrency = flag;
    },
    selectedCurrency(flag, currency) {
      // this function is passed as a callback to the response modal for Currency input form when any value is selected in response modal, this function collects the value and assigns it to the desired variable
      this.showOpenCurrency = flag;
      this.currency = currency;
    },
    openValueSetModal(vsetCode) {
      // this function opens up the value set modal based on the paramater passed and received
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeout = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      // this function selects one of the values that is selected in the value set modals
      if (this.vsetCode === appStrings.VALUESETTYPE.PAYMENT_MODE) {
        this.selectedPaymentMode = {
          value: item.value_code,
          text: item.value_meaning
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.BANK_NAME_VSET) {
        this.selectedBank.value = item.value_code;
        this.selectedBank.text = item.value_meaning;
      }
    },
    closeValueSetModal() {
      // this function closes the value set modals on press of cross button
      this.showValueSetModal = false;
    },
    setRemainingAmount($event) {
      this.remainingAmout = $event.target.value;
    },
    onlyNumber($event) {
      // this function makes sure that the entered key is a number
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
    },
    checkIntrumentFomrValidation() {
      // this function checks that if Intrument Validation tab is complete only then can this tab be active
      let validate = false;
      if (
        this.selectedPaymentMode.value !== 'CSH' &&
        this.selectedPaymentMode.value !== 'EFT' &&
        this.selectedPaymentMode.value !== 'TDS Receivable'
      ) {
        validate = this.instrumentNum === null;
      }
      return (
        (!this.editMode && !this.selectedPaymentMode.value) ||
        validate ||
        this.amount === null
      );
    },
    getReceiptMethod() {
      // this function gets the values of receipt method input form and shows it in the response modal
      const payload = {
        org_id: this.organizationId,
        _page: this.currentPage - 1,
        _limit: this.customerPerPage
      };
      this.loading = true;
      this.$store
        .dispatch('customerBalances/customerBalancesReceiptMethod', payload)
        .then(response => {
          if (response.status === 200) {
            this.receiptMethodData = response.data.data.data.page;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    receiptMethodModal(flag) {
      // this function sets and changes the state of the modal that opens on click of Receipt Method input form
      this.showOpenReceiptMethod = flag;
    },
    selectedReceiptMethod(flag, receiptmethod) {
      // this function is passed as a callback to the response modal for Receipt Method input form when any value is selected in response modal, this function collects the value and assigns it to the desired variable
      this.showOpenReceiptMethod = flag;
      this.receiptMethod = receiptmethod;
    },
    getOutstandingData() {
      // this function gets the values of Outstanding Data from previous page for that particular customer
      const payload = {
        _page: this.currentPage - 1,
        _limit: 10,
        legal_entity_id: '',
        organization_id: '',
        customer_id: this.customerId,
        customer_nam: '',
        customer_number: '',
        class_type: '',
        status: '',
        trx_number: '',
        gl_date_from: '',
        gl_date_to: '',
        hq_name: '',
        orderBy: '',
        orderName: ''
      };
      this.loading = true;
      this.$store
        .dispatch('customerBalances/getCustomerBalancesData', payload)
        .then(response => {
          if (response.status === 200) {
            this.outstandingData = response.data.data.data.page;
            this.outstandingData = this.outstandingData.filter(item => {
              return item.class_type === 'INV' || item.class_type === 'DM';
            });
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    openTaxCatModal(flag, index) {
      // this function sets and opens the modal that opens up on click of Tax Category input form
      this.showTaxCategoryFlag = flag;
      this.taxIndex = index;
    },
    addEditReceipt() {
      // this is the main function that adds up the receipt
      let total = 0;
      for (let i of this.outstandingData) {
        total = +total + +i.applied_amount;
      }
      this.totalAppliedAmount = total;
      if ((
        this.legalEntity.value !== null ||
        this.customerName !== null ||
        this.instrumentNum !== null ||
        this.selectedPaymentMode !== null ||
        this.amount !== null ||
        this.receiptMethod !== null) && (this.totalAppliedAmount > this.remainingAmout)
      ) {
        alert('Total Applied Amount is greater than Total Outstanding Amount! Please Check.');
      } else if(
        this.legalEntity.value === null ||
        this.customerName === null ||
        this.instrumentNum === null ||
        this.selectedPaymentMode === null ||
        this.amount === null ||
        this.receiptMethod === null) {
          alert("Please fill in all the mandatory fields!");
      } else {
        const payload = {
          PI_OU_ID: this.operatingUnit.value,
          PI_RECEIPT_NUMBER: this.receiptNumber,
          PI_RECEIPT_METHOD_ID: this.receiptMethod.receiptmethodid,
          PI_CUSTOMER_ID: this.customerId,
          PI_CUSTOMER_SITE_USE_ID: this.customerSite.value,
          PI_GL_DATE: this.gl_date,
          PI_RECEIPT_AMOUNT: this.amount,
          PI_RECEIPT_DATE: this.instrument_date,
          PI_CURRENCY_CODE: this.currency.value,
          PI_EXCHANGE_RATE_TYPE: this.exchangeType,
          PI_EXCHANGE_RATE_DATE: null
        };
        this.loading = true;
        this.$store
          .dispatch('customerBalances/customerBalancesCreateReceipt', payload)
          .then(response => {
            if (response.status === 200) {
              this.responseMsg = response.data.data.data.message;
              this.makeToast(this.responseMsg, 'success');
              this.poReceiptId = response.data.data.data.po_receipt_id;
              if (
                this.poReceiptId &&
                this.outstandingData[0].trx_number !== ''
              ) {
                this.applyReceipt();
              }
            }
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            this.makeToast(err.message, 'danger');
          });
      }
    },
    addNewRow() {
      // this function adds a new row on click of add button inside the outstanding table
      // if (
      //   this.outstandingData[this.outstandingIndex].applied_amount >
      //   this.outstandingData[this.outstandingIndex].amount_due
      // ) {
      //   alert('Applied Amount is greater than Amount Due! Please Check');
      // } else {
      //   this.outstandingData.push({
      //     trx_number: '',
      //     amount_original: '',
      //     amount_due: '',
      //     gl_date: format(new Date(), appStrings.DATEFNSFORMAT),
      //     apply_date: format(new Date(), appStrings.DATEFNSFORMAT),
      //     class_type: ''
      //   });
      // }
      if (
        this.outstandingData[this.outstandingIndex].trx_number !== 'WRITEOFF' &&
        this.outstandingData[this.outstandingIndex].trx_number !== 'ONACCOUNT'
      ) {
        if (
          this.outstandingData[this.outstandingIndex].applied_amount >
          this.outstandingData[this.outstandingIndex].amount_due
        ) {
          alert('Applied Amount is greater than Amount Due! Please Check');
        } else {
          this.outstandingData.push({
            trx_number: '',
            amount_original: '',
            amount_due: '',
            gl_date: format(new Date(), appStrings.DATEFNSFORMAT),
            apply_date: format(new Date(), appStrings.DATEFNSFORMAT),
            class_type: ''
          });
        }
      } else if (
        this.outstandingData[this.outstandingIndex].trx_number === 'WRITEOFF' ||
        this.outstandingData[this.outstandingIndex].trx_number === 'ONACCOUNT'
      ) {
        this.outstandingData.push({
          trx_number: '',
          amount_original: '',
          amount_due: '',
          gl_date: format(new Date(), appStrings.DATEFNSFORMAT),
          apply_date: format(new Date(), appStrings.DATEFNSFORMAT),
          class_type: ''
        });
      }
    },
    removeRow(index) {
      // this function removes the row on click of add button inside the outstanding table
      this.outstandingData.splice(index, 1);
    },
    transactionNumberModal(flag) {
      this.showOpenTransactionNumber = flag;
    },
    selectedTransactionNumber(flag, transactionNumber) {
      this.showOpenTransactionNumber = flag;
      this.transactionNumbers = transactionNumber;
    },
    gstTransactionNumber() {
      const payload = {
        bill_to_customer_id: 4190697,
        org_id: this.previousPageData[0].organization_id,
        _page: this.modalCurrentPage - 1,
        _limit: 10
      };
      this.loading = true;
      this.$store
        .dispatch('customerBalances/customerBalancesTransactionNumber', payload)
        .then(response => {
          if (response.status === 200) {
            this.transactionNumberData = response.data.data.data.page;
            this.modalTotalRows = response.data.data.data.total_elements;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    applyReceipt() {
      // this function applys the receipt
      let total = 0;
      for (let i of this.outstandingData) {
        total = +total + +i.applied_amount;
      }
      this.totalAppliedAmount = total;
      if (
        this.outstandingData[this.outstandingIndex].trx_number === 'WRITEOFF' ||
        this.outstandingData[this.outstandingIndex].trx_number === 'ONACCOUNT'
      ) {
        if (this.totalAppliedAmount > this.remainingAmout) {
          alert(
            'Total Applied Amount is geater than the Total Outstanding Amount! Please Check.'
          );
        } else {
          this.apply();
        }
      } else if (
        this.outstandingData[this.outstandingIndex].trx_number !== 'WRITEOFF' &&
        this.outstandingData[this.outstandingIndex].trx_number !== 'ONACCOUNT'
      ) {
        if (
          this.totalAppliedAmount > this.remainingAmout ||
          this.outstandingData[this.outstandingIndex].applied_amount >
            this.outstandingData[this.outstandingIndex].amount_due
        ) {
          alert(
            'Applied Amount is greater than the Amount Due or Total Applied Amount is greater than the Total Outstanding Amount! Please Check.'
          );
        } else {
          this.apply();
        }
      }
    },
    apply() {
      for (let i of this.outstandingData) {
        this.outstandingPayload.push({
          ou_id: this.previousPageData[0].organization_id,
          transaction_id: i.transaction_id,
          transaction_num: i.trx_number,
          transaction_type: i.class_type,
          applied_amount: i.amount_original,
          gl_date: i.gl_date,
          apply_date: i.apply_date
        });
      }
      const payload = {
        cash_receipt_id: this.poReceiptId,
        Attributes: this.outstandingPayload
      };
      this.loading = true;
      this.$store
        .dispatch('customerBalances/customerBalancesApplyReceipt', payload)
        .then(response => {
          if (response.status === 200) {
            this.responseMsg = response.data.data.data.po_message;
            this.makeToast(this.responseMsg, 'success');
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.makeToast(err.message, 'danger');
        });
    },
    makeToast(content, variant) {
      this.$bvToast.toast(content, {
        title: 'Alert',
        variant: variant,
        solid: true
      });
    },
  },
  created() {
    this.eventBus.$on('transactionNumbers', transactionNumbers => {
      this.transactionNumbers = transactionNumbers;
      this.outstandingData[
        this.outstandingIndex
      ].trx_number = this.transactionNumbers.transactionNumber;
      this.outstandingData[
        this.outstandingIndex
      ].class_type = this.transactionNumbers.trxType;
      this.outstandingData[
        this.outstandingIndex
      ].transaction_id = this.transactionNumbers.customerTrxId;
      this.outstandingData[
        this.outstandingIndex
      ].amount_original = this.transactionNumbers.amountOriginal;
      this.outstandingData[
        this.outstandingIndex
      ].amount_due = this.transactionNumbers.amountDue;
    });
  },
  beforeDestroy() {
    this.eventBus.$off('transactionNumbers');
    this.unsubscribe();
  }
};
